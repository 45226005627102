import { request, stopSpinner } from './helper.actions';
import { api } from './api-lib/api';


export const GET_SCREENSHOT_SUCCESS = 'GET_SCREENSHOT_SUCCESS';
export const GET_SCREENSHOT_FAILURE = 'GET_SCREENSHOT_FAILURE';
export const SEND_SCREENSHOT_SUCCESS = 'SEND_SCREENSHOT_SUCCESS';
export const SEND_SCREENSHOT_FAILURE = 'SEND_SCREENSHOT_FAILURE';
export const ADD_SINGLE_SCREEN_SHOT_TO_LIST = 'ADD_SINGLE_SCREEN_SHOT_TO_LIST';
export const NAVIGATE_SLIDE = 'NAVIGATE_SLIDE';


const getScreenshotSuccess = screenshots => (
  {
    type: GET_SCREENSHOT_SUCCESS,
    screenshots,
  }
);

const getScreenshotFailure = error => (
  {
    type: GET_SCREENSHOT_FAILURE,
    error,
  }
);

export const getScreenShot = (deviceName) => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/screenshot`)
    .then(resp => {
      let screenshots = resp.data || [];
      dispatch(getScreenshotSuccess(screenshots));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getScreenshotFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};


const sendScreenShotSuccess = resp => ({
  type: SEND_SCREENSHOT_SUCCESS,
  resp,
});

const sendScreenShotFailure = error => ({
  type: SEND_SCREENSHOT_FAILURE,
  error,
});

export const sendScreenShotType = (deviceId, type, spinnerstatus) => (dispatch) => {
  dispatch(request(spinnerstatus));
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceId}/screenshot/${type}`)
    .then(resp => {
      dispatch(sendScreenShotSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(sendScreenShotFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

export const addSingleScreenShotToList = screenshot => dispatch => {
  return dispatch({
    type: ADD_SINGLE_SCREEN_SHOT_TO_LIST,
    screenshot,
  });
};

export const navigateSlides = index => dispatch => {
  return dispatch({
    type: NAVIGATE_SLIDE,
    index,
  })
}
