import { api } from './api-lib/api';
import { request, stopSpinner } from './helper.actions';
export const GET_CURRENT_DEVICE_CONTENT_SUCCESS = 'GET_CURRENT_DEVICE_CONTENT_SUCCESS';
export const GET_CURRENT_DEVICE_CONTENT_FAILURE = 'GET_CURRENT_DEVICE_CONTENT_FAILURE';
export const GET_DEVICE_CONTENT_SUCCESS = 'GET_DEVICE_CONTENT_SUCCESS';
export const GET_DEVICE_CONTENT_FAILURE = 'GET_DEVICE_CONTENT_FAILURE';
export const UPDATE_DEVICE_CONTENT_SUCCESS = 'UPDATE_DEVICE_CONTENT_SUCCESS';
export const UPDATE_DEVICE_CONTENT_FAILURE = 'UPDATE_DEVICE_CONTENT_FAILURE';
export const REFRESH_DEVICE_CONTENT_SUCCESS = 'REFRESH_DEVICE_CONTENT_SUCCESS';
export const REFRESH_DEVICE_CONTENT_FAILURE = 'REFRESH_DEVICE_CONTENT_FAILURE';

const getCurrentDeviceContentSuccess = resp => (
    {
      type: GET_CURRENT_DEVICE_CONTENT_SUCCESS,
      resp,
    }
  );
  
  const getCurrentDeviceContentFailure= error => (
    {
      type: GET_CURRENT_DEVICE_CONTENT_FAILURE,
      error,
    }
);


const getDeviceContentSuccess = resp => (
  {
    type: GET_DEVICE_CONTENT_SUCCESS,
    resp,
  }
);

const getDeviceContentFailure= error => (
  {
    type: GET_DEVICE_CONTENT_FAILURE,
    error,
  }
);

const updateDeviceContentSuccess = resp => (
    {
      type: UPDATE_DEVICE_CONTENT_SUCCESS,
      resp,
    }
  );
  
const updateDeviceContentFailure= error => (
    {
      type: UPDATE_DEVICE_CONTENT_FAILURE,
      error,
    }
);

export const getCurrentDeviceContent = (deviceName) => (dispatch) => {
    dispatch(request())
    return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/content`)
      .then(resp => {
        dispatch(getCurrentDeviceContentSuccess(resp));
        dispatch(stopSpinner());
        return Promise.resolve(resp);
      })
      .catch(error => {
        dispatch(getCurrentDeviceContentFailure(error));
        dispatch(stopSpinner());
        return Promise.reject(error);
      })
};


export const getDeviceContentGroups = payload => (dispatch) => {
    dispatch(request())
    return api.get(`/companies/${localStorage.getItem('companyId')}/sceneGroups`)
      .then(resp => {
        dispatch(getDeviceContentSuccess(resp));
        dispatch(stopSpinner());
        return Promise.resolve(resp);
      })
      .catch(error => {
        dispatch(getDeviceContentFailure(error));
        dispatch(stopSpinner());
        return Promise.reject(error);
      })
  };
  
export const updateDeviceContent = (deviceId, content) => (dispatch) => {
    dispatch(request()) 
    return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceId}/content`, content)
    .then(resp => {
      dispatch(updateDeviceContentSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(updateDeviceContentFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
  }


const refreshDeviceContentSuccess = resp => (
    {
      type: REFRESH_DEVICE_CONTENT_SUCCESS,
      resp,
    }
  );
  
const refreshDeviceContentFailure= error => (
    {
      type: REFRESH_DEVICE_CONTENT_FAILURE,
      error,
    }
);

export const refreshDeviceContent = (device) => (dispatch) => {
    dispatch(request())
    let endpoint = `/companies/${localStorage.getItem('companyId')}/devices/${device.deviceName}/content/refresh`
    if(device.refreshNow){
      endpoint += `?refreshNow=${device.refreshNow}&&refreshContentStatus=disable`
    } else {
      endpoint += `?refreshContentStatus=${device.refreshContent.status}&&refreshContentForEvery=${device.refreshContent.refreshContentForEvery}`
    }
    return api.get(endpoint)
      .then(resp => {
        dispatch(refreshDeviceContentSuccess(resp));
        dispatch(stopSpinner());
        return Promise.resolve(resp);
      })
      .catch(error => {
        dispatch(refreshDeviceContentFailure(error));
        dispatch(stopSpinner());
        return Promise.reject(error);
      })
};