export const API_REQUEST = 'API_REQUEST';
export const SCROLL_PAGE = 'SCROLL_PAGE';
export const MODAL_DISPLAY = 'MODAL_DISPLAY';
export const DATA_LOADING_STATE = 'DATA_LOADING_STATE';
export const DATA_LOADED_STATE = 'DATA_LOADED_STATE';
export const RESET_PASSWORD_FIELD = 'RESET_PASSWORD_FIELD';

export const request = (hidespinner) => (
  hidespinner ? ({
    type: API_REQUEST,
  }) : ({
    type: API_REQUEST,
  },
    {
      type: DATA_LOADING_STATE,
    })
);

export const stopSpinner = () => ({
    type: DATA_LOADED_STATE,
  });


export const scrollToRef = (domReference) => {
  return {
    type: SCROLL_PAGE,
    domReference,
  }
}

export const handleCustomModal = (flag) => ({
  type: MODAL_DISPLAY,
  flag,
});


export const resetPasswordField = (resetPasswordFieldFn) => {
  return {
    type: RESET_PASSWORD_FIELD,
    resetPasswordFieldFn
  }
}
