import {
  HANDLE_REGISTER_MODAL,
} from '../actions/register.actions';
import {
  API_REQUEST
} from '../actions/helper.actions';

const initialState = {
  pending: false,
  isregisterModalOpen: false,
}

const register = (state = initialState, action) => {
  switch(action.type){
    case HANDLE_REGISTER_MODAL:
    return {
      ...state,
      isregisterModalOpen: action.flag
    }
    case API_REQUEST:
    return{
      ...state,
      pending: true,
    }
    default:
    return state
  }
}
export default register;