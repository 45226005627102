import {
  SEND_SCREENSHOT_SUCCESS,
  SEND_SCREENSHOT_FAILURE,
  GET_SCREENSHOT_SUCCESS,
  GET_SCREENSHOT_FAILURE,
  ADD_SINGLE_SCREEN_SHOT_TO_LIST,
  NAVIGATE_SLIDE
} from "../actions/screenshot.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  screenShots: [],
  currentSlideIndex: 0,
  pending: false,
};

const screenShot = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case SEND_SCREENSHOT_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case SEND_SCREENSHOT_FAILURE:
      return {
        ...state,
        pending: false
      };
    case GET_SCREENSHOT_SUCCESS:
      return {
        ...state,
        pending: false,
        screenShots: action.screenshots
      }
    case GET_SCREENSHOT_FAILURE:
      return {
        ...state,
        ...initialState,
      }
    case ADD_SINGLE_SCREEN_SHOT_TO_LIST:
      const newScreenShots = JSON.parse(JSON.stringify(state.screenShots));
      newScreenShots.unshift({images:[{...action.screenshot, id: Date.now()}], id: Date.now()})
      return {
        ...state,
        screenShots: newScreenShots,
        currentSlideIndex: 0
      }
    case NAVIGATE_SLIDE:
      return {
        ...state,
        currentSlideIndex: action.index,
      }
    default:
      return state;
  }
};

export default screenShot;
