import { api } from './api-lib/api';
import {
  request,
  stopSpinner
} from './helper.actions';
export const HANDLE_F_PASSWORD_MODAL = 'HANDLE_F_PASSWORD_MODAL';
export const F_PASSWORD_SUCCESS = 'F_PASSWORD_SUCCESS';
export const F_PASSWORD_FAILURE = 'F_PASSWORD_FAILURE';

export const handleForgotPasswordModal = (flag) => ({
  type: HANDLE_F_PASSWORD_MODAL,
  flag
});

const fPasswordSuccess = resp => (
  {
    type: F_PASSWORD_SUCCESS,
    resp,
  }
);

const fPasswordFailure = error => (
  {
    type: F_PASSWORD_FAILURE,
    error,
  }
);

export const changePassword = payload => (dispatch) => {
  dispatch(request())
  return api.post('/forgot-password', { ...payload })
    .then(resp => {
      dispatch(fPasswordSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(fPasswordFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};