import { GET_TAG_SUCCESS, GET_TAG_FAILURE } from '../actions/tag.actions';

let inititalState = {
    tags: {},
    pending: false
};

const tags = (state = inititalState, action) => {

    switch(action.type) {
        case GET_TAG_SUCCESS: 
            return {
                ...state,
                tags: action.tags,
                pending: false
            };
        case GET_TAG_FAILURE: 
            return {
                ...state,
                tags: [],
                pending: false
            };
        default: 
            return {
                ... state
            };    
    }
};

export default tags;