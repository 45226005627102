import {
  GET_DEVICES_SUCCESS,
  GET_DEVICES_FAILURE,
  GET_DEVICE_SUCCESS,
  GET_DEVICE_FAILURE,
  UPDATE_DEVICE_STATUS_SUCCESS,
  UPDATE_DEVICE_STATUS_FAILURE,
  GET_UNREGISTERED_DEVICES_SUCCESS,
  GET_UNREGISTERED_DEVICES_FAILURE,
  UPDATE_UNREGISTERED_DEVICE_SUCCESS,
  UPDATE_UNREGISTERED_DEVICE_FAILURE,
  CREATE_UNREGISTER_DEVICE_SUCCESS,
  CREATE_UNREGISTER_DEVICE_FAILURE,
  DELETE_DEVICE_SUCCESS,
  DELETE_DEVICE_FAILURE,
  GET_DEVICE_CPU_USAGES_SUCCESS,
  GET_DEVICE_CPU_USAGES_FAILURE,
  CREATE_BULK_DEVICES_SUCCESS,
  CREATE_BULK_DEVICES_FAILURE,
  // GET_DEVICE_MEDIA_SUCCESS,
  // GET_DEVICE_MEDIA_FAILURE
  DASHBOARD_FILTER_DEVICE_SUCCESS,
  DASHBOARD_FILTER_DEVICE_FAILURE,
  UNLIST_DEVICES_FOR_FILTER_SUCCESS,
  UNLIST_UNREGISTERED_DEVICES_SUCCESS
} from '../actions/devices.actions';
import {
  API_REQUEST
} from '../actions/helper.actions';

const initialState = {
  pending: false,
  items: [],
  item: {},
  unregisteredItems: [],
  deviceCpuMemObj: [],
  nextpageAvailable: false,
  pageCountForDasboradFilter: 1
}

const device = (state = initialState, action) => {
  switch (action.type) {

    case GET_DEVICES_SUCCESS:
      let newArr = []
      let arrCopy = []
      if(action.resp.data.page === 1){
        newArr = action.resp.data.devices
      } else {
        arrCopy = JSON.parse(JSON.stringify(state.items));
        newArr = arrCopy.concat(action.resp.data.devices)
      }
      return {
        ...state,
        nextpageAvailable: ((action.resp.pages - action.resp.page) <= 0) ? false : true,
        items: newArr,
        pending: false,
      }

    case GET_DEVICES_FAILURE:
      return {
        ...state,
        items: [],
        pending: false,
      }

    case GET_DEVICE_SUCCESS:
      return {
        ...state,
        pending: false,
        item: action.resp.data.device && action.resp.data.device[0] || []
      }

    case GET_DEVICE_FAILURE:
      return {
        ...state,
        item: {},
        pending: false
      }

    case UPDATE_DEVICE_STATUS_SUCCESS:
      return {
        ...state,
        item: action.resp.data.device,
        pending: false,
      }

    case UPDATE_DEVICE_STATUS_FAILURE:
      return {
        ...state,
        item: {},
        pending: false,
      }

    case GET_UNREGISTERED_DEVICES_SUCCESS:
      let unRegisteredArr = []
      let unRegisteredArrCopy = []
      let paginate = action.resp.data.deviceAvailibleAfterPaginate;
      if(paginate && paginate.page === 1){
        unRegisteredArr = action.resp.data.devices
      } else {
        unRegisteredArrCopy = JSON.parse(JSON.stringify(state.unregisteredItems));
        unRegisteredArr = unRegisteredArrCopy.concat(action.resp.data.devices)
      }
      return {
        ...state,
        unregisteredItems: unRegisteredArr,
        pageCountForUnregisteredDevices: paginate && paginate.page,
        nextpageAvailable: ((paginate.pages - paginate.page) <= 0 ) ? false : true,
        pending: false,
      }

    case GET_UNREGISTERED_DEVICES_FAILURE:
      return {
        ...state,
        unregisteredItems: [],
        nextpageAvailable: false,
        pending: false,
      }

    case UPDATE_UNREGISTERED_DEVICE_SUCCESS:
      return {
        ...state,
        pending: false,
      }

    case UPDATE_UNREGISTERED_DEVICE_FAILURE:
      return {
        ...state,
        pending: false,
      }

    case CREATE_UNREGISTER_DEVICE_SUCCESS:
      return {
        ...state,
        pending: false,
      }

    case CREATE_UNREGISTER_DEVICE_FAILURE:
      return {
        ...state,
        pending: false,
      }

    case DELETE_DEVICE_SUCCESS:
      return {
        ...state,
        pending: false,
      }

    case DELETE_DEVICE_FAILURE:
      return {
        ...state,
        pending: false,
      }

    case GET_DEVICE_CPU_USAGES_SUCCESS:
      return {
        ...state,
        pending: false,
        deviceCpuMemObj: action.resp.data.deviceCpuMemObj
      }

    case GET_DEVICE_CPU_USAGES_FAILURE:
      return {
        ...state,
        pending: false,
      }

    case CREATE_BULK_DEVICES_SUCCESS:
      return {
        ...state,
        pending: false,
      }

    case CREATE_BULK_DEVICES_FAILURE:
      return {
        ...state,
        pending: false,
      }
    
    case DASHBOARD_FILTER_DEVICE_SUCCESS:
      let filterNewArr = []
      let filteredArrCopy = []
      if(action.resp.data.page === 1){
        filterNewArr = action.resp.data.devices
      } else {
        filteredArrCopy = JSON.parse(JSON.stringify(state.items));
        filterNewArr = filteredArrCopy.concat(action.resp.data.devices)
      }
      return {
        ...state,
        nextpageAvailable: ((action.resp.data.pages - action.resp.data.page) <= 0) ? false : true,
        items: filterNewArr,
        pageCountForDasboradFilter: action.resp.data.page,
        pending: false,
      }
  
    case DASHBOARD_FILTER_DEVICE_FAILURE:
      return {
        ...state,
        items: [],
        pending: false,
      }
    
      case UNLIST_DEVICES_FOR_FILTER_SUCCESS:
        return {
          ...state,
          items: [],
          pending: false,
        }
      
        case UNLIST_UNREGISTERED_DEVICES_SUCCESS:
        return {
          ...state,
          unregisteredItems: [],
          pending: false,
        }
        
    default:
      return state
  }
}

export default device;