import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const NOTIFICATION_DETAILS_SUCCESS = "NOTIFICATION_SUCCESS";
export const NOTIFICATION_DETAILS_FAILURE = "NOTIFICATION_FAILURE";
export const UPDATE_NOTIFICATION_SUCCESS =
  "UPDATE_NOTIFICATION_DETAILS_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE =
  "UPDATE_NOTIFICATION_DETAILS_FAILURE";

const notificationDetailsSuccess = res => {
  return {
    type: NOTIFICATION_DETAILS_SUCCESS,
    res
  };
};

const notificationDetailsFailure = error => {
  return {
    type: NOTIFICATION_DETAILS_FAILURE,
    error
  };
};

const updateNotificationSuccess = res => {
  return {
    type: UPDATE_NOTIFICATION_SUCCESS,
    res
  };
};
const updateNotificationFailure = res => {
  return {
    type: UPDATE_NOTIFICATION_FAILURE,
    res
  };
};

export const getNotificationDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/notification/`)
    .then(resp => {
			dispatch(notificationDetailsSuccess(resp));
			dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
			dispatch(notificationDetailsFailure(error));
			dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const updateNotificationDetails = payload => dispatch => {
  dispatch(request());
  return api
    .put(`/companies/${localStorage.getItem("companyId")}/notification/`, {
      ...payload
    })
    .then(resp => {
			dispatch(updateNotificationSuccess(resp));
			dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
			dispatch(updateNotificationFailure(error));
			dispatch(stopSpinner());
      return Promise.reject(error);
    });
};
