import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const GET_DEVICE_SOFTWARE_SUCCESS = "GET_DEVICE_SOFTWARE_SUCCESS";
export const GET_DEVICE_SOFTWARE_FAILURE = "GET_DEVICE_SOFTWARE_FAILURE";

export const UPDATE_DEVICE_SOFTWARE_SUCCESS = "UPDATE_DEVICE_SOFTWARE_SUCCESS";
export const UPDATE_DEVICE_SOFTWARE_FAILURE = "UPDATE_DEVICE_SOFTWARE_FAILURE";

const getDeviceSoftwareSuccess = resp => ({
  type: GET_DEVICE_SOFTWARE_SUCCESS,
  resp
});

const getDeviceSoftwareFailure = error => ({
  type: GET_DEVICE_SOFTWARE_FAILURE,
  error
});

export const getDeviceSoftware = deviceName => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/software`)
    .then(resp => {
      dispatch(getDeviceSoftwareSuccess(resp.data.device.softwares));
      dispatch(stopSpinner());
    })
    .catch(error => {
      dispatch(getDeviceSoftwareFailure(error));
      dispatch(stopSpinner());
    });
};

const updateDeviceSoftwareSuccess = resp => ({
  type: UPDATE_DEVICE_SOFTWARE_SUCCESS,
  resp
});

const updateDeviceSoftwareFailure = error => ({
  type: UPDATE_DEVICE_SOFTWARE_FAILURE,
  error
});

export const updateDeviceSoftware = (deviceName, payload) => dispatch => {
  dispatch(request());
  return api
    .put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/software`, payload)
    .then(resp => {
      dispatch(updateDeviceSoftwareSuccess(resp.data.software));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(updateDeviceSoftwareFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};
