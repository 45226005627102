import {
    GET_FILTER_DEVICE_SUCCESS,
    GET_FILTER_DEVICE_FAILURE,
    GET_DEVICE_SEARCH_SUCCESS,
    GET_DEVICE_SEARCH_FAILURE,
    UNLIST_DEVICES_FOR_SEARCH_SUCCESS,
    SET_REDUX_STORE_FOR_DEVICE_SEARCH,
  } from "../actions/filtered.actions";

  import { API_REQUEST } from "../actions/helper.actions";
   
  const initialState = {
    filterDevices: [],
    nextpageAvailable: false,
    searchObject: {},
    currentPageCount: 1,
  };
  
  const filteredData = (state = initialState, action) => {
    switch (action.type) {
      case API_REQUEST:
        return {
          ...state,
          pending: true
        };
      case GET_FILTER_DEVICE_SUCCESS:
        let newArr = []
        let arrCopy = []
        if(action.resp.page === 1){
          newArr = action.resp.devices
        } else {
          arrCopy = JSON.parse(JSON.stringify(state.filterDevices));
          newArr = arrCopy.concat(action.resp.devices)
        }
        return {
          ...state,
          filterDevices: newArr,
          currentPageCount: action.resp.page,
          searchObject: { searchKey: action.searchKey, searchBy: action.searchBy, sortDevicesIn: action.sortDevicesIn },
          nextpageAvailable: ((action.resp.pages - action.resp.page) <= 0) ? false : true,
          pending: false,
        }
      case GET_FILTER_DEVICE_FAILURE:
        return {
          ...state,
          filterDevices:[],
          nextpageAvailable: false,
          pending: false
        };
      case GET_DEVICE_SEARCH_SUCCESS:
        let searchNewArr = []
        let searchArrCopy = []
        if(action.resp.page === 1){
          searchNewArr = action.resp.devices
        } else {
          searchArrCopy = JSON.parse(JSON.stringify(state.filterDevices));
          searchNewArr = searchArrCopy.concat(action.resp.devices)
        }
        return {
          ...state,
          filterDevices: searchNewArr,
          currentPageCount: action.resp.page,
          searchObject: { searchKey: action.searchKey, searchBy: action.searchBy, sortDevicesIn: action.sortDevicesIn },
          nextpageAvailable: ((action.resp.pages - action.resp.page) <= 0) ? false : true,
          pending: false
        }
      case SET_REDUX_STORE_FOR_DEVICE_SEARCH:
        return {
          ...state,
          filterDevices: [],
          currentPageCount: action.pageCount,
          searchObject: { searchKey: action.searchKey, searchBy: action.searchBy, sortDevicesIn: action.sortDevicesIn },
          pending: false
        }
      case GET_DEVICE_SEARCH_FAILURE:
        return {
          ...state,
          filterDevices:[],
          nextpageAvailable: false,
          pending: false
        };
      
        case UNLIST_DEVICES_FOR_SEARCH_SUCCESS:
          return {
            ...state,
            filterDevices: [],
            nextpageAvailable: false
          }
      default:
        return state;
    }
  };
  
  export default filteredData;
  