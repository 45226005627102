import { request, stopSpinner } from './helper.actions';
import { api } from './api-lib/api';
export const LIST_SCENE_GROUP_SUCCESS = 'LIST_SCENE_GROUP_SUCCESS';
export const LIST_SCENE_GROUP_FAILURE = 'LIST_SCENE_GROUP_FAILURE';
export const LIST_SCENES_SUCCESS = 'LIST_SCENES_SUCCESS';
export const LIST_SCENES_FAILURE = 'LIST_SCENES_FAILURE';
export const LIST_SCENE_TYPE_SUCCESS = 'LIST_SCENE_TYPE_SUCCESS';
export const LIST_SCENE_TYPE_FAILURE = 'LIST_SCENE_TYPE_FAILURE';
export const UPDATE_SCENE_GROUP_SUCCESS = 'UPDATE_SCENE_GROUP_SUCCESS';
export const UPDATE_SCENE_GROUP_FAILURE = 'UPDATE_SCENE_GROUP_FAILURE';
export const CREATE_SCENE_GROUP_SUCCESS = 'CREATE_SCENE_GROUP_SUCCESS';
export const CREATE_SCENE_GROUP_FAILURE = 'CREATE_SCENE_GROUP_FAILURE';
export const DELETE_SCENE_SUCCESS = 'DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_FAILURE = 'DELETE_SCENE_FAILURE';
export const GET_SCENES_BY_TYPE_SUCCESS = 'GET_SCENES_BY_TYPE_SUCCESS';
export const GET_SCENES_BY_TYPE_FAILURE = 'GET_SCENES_BY_TYPE_FAILURE';
export const DELETE_SCENE_GROUP_SUCCESS = 'DELETE_SCENE_GROUP_SUCCESS';
export const DELETE_SCENE_GROUP_FAILURE = 'DELETE_SCENE_GROUP_FAILURE';

const sceneGroupListSuccess = resp => ({
  type: LIST_SCENE_GROUP_SUCCESS,
  resp,
});

const sceneGroupListFailure = error => ({
  type: LIST_SCENE_GROUP_FAILURE,
  error,
});

export const listSceneGroups = () => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/sceneGroups`)
  .then(resp => {
    dispatch(sceneGroupListSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(sceneGroupListFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const getSceneGroupSuccess = resp => ({
  type: LIST_SCENES_SUCCESS,
  resp,
});

const getSceneGroupFailure = error => ({
  type: LIST_SCENES_FAILURE,
  error,
});

export const getSceneGroup = sceneGroupId => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/sceneGroups/${sceneGroupId}`)
  .then(resp => {
    dispatch(getSceneGroupSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(getSceneGroupFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const listSceneTypeSuccess = resp => ({
  type: LIST_SCENE_TYPE_SUCCESS,
  resp,
});

const listSceneTypeFailure = error => ({
  type: LIST_SCENE_TYPE_FAILURE,
  error,
});

export const listSceneTypes = () => (dispatch) => {
  return api.get('/sceneTypes')
  .then(resp => {
    dispatch(listSceneTypeSuccess(resp));
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(listSceneTypeFailure(error));
    return Promise.reject(error);
  })
};

const updateSceneTypeSuccess = resp => ({
  type: LIST_SCENE_TYPE_SUCCESS,
  resp,
});

const updateSceneTypeFailure = error => ({
  type: LIST_SCENE_TYPE_FAILURE,
  error,
});

export const updateSceneType = (sceneGroupId) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/sceneGroups/${sceneGroupId}`)
  .then(resp => {
    dispatch(updateSceneTypeSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(updateSceneTypeFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const deleteSceneSuccess = resp => ({
  type: DELETE_SCENE_SUCCESS,
  resp,
});

const deleteSceneFailure = error => ({
  type: DELETE_SCENE_FAILURE,
  error,
});

export const deleteScene = (sceneId) => (dispatch) => {
  dispatch(request());
  return api.delete(`/companies/${localStorage.getItem('companyId')}/scenes/${sceneId}`)
  .then(resp => {
    dispatch(deleteSceneSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(deleteSceneFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};
const getScenesByTypeSuccess = (resp) => {
  return {
    type: GET_SCENES_BY_TYPE_SUCCESS,
    resp,
  }
};

const getScenesByTypeFailure = (error) => {
  return {
    type: GET_SCENES_BY_TYPE_SUCCESS,
    error,
  }
}

export const getScenesByType = (sceneType) => (dispatch) => {
  return api.get(`/sceneTypes/${sceneType}?companyName=${localStorage.getItem('companyId')}`)
  .then(resp => {
    dispatch(getScenesByTypeSuccess(resp))
    return Promise.resolve(resp);
  }).catch(error => {
    dispatch(getScenesByTypeFailure(error));
    return Promise.reject(error);
  })
}

const createSceneGroupSuccess = (resp) => {
  return {
    type: CREATE_SCENE_GROUP_SUCCESS,
    resp,
  }
}

const createSceneGroupFailure = (error) => {
  return {
    type: CREATE_SCENE_GROUP_FAILURE,
    error,
  }
}

export const createSceneGroup = (body) => (dispatch) => {
  dispatch(request());
  return api.post(`/companies/${localStorage.getItem('companyId')}/sceneGroups`, body)
  .then(resp => {
    dispatch(createSceneGroupSuccess(resp))
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  }).catch(error => {
    dispatch(createSceneGroupFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}

const updateSceneGroupSuccess = (resp) => {
  return {
    type: UPDATE_SCENE_GROUP_SUCCESS,
    resp,
  }
}

const updateSceneGroupFailure = (error) => {
  return {
    type: UPDATE_SCENE_GROUP_FAILURE,
    error,
  }
}

export const updateSceneGroup = (sceneGroupId, body) => (dispatch) => {
  return api.put(`/companies/${localStorage.getItem('companyId')}/sceneGroups/${sceneGroupId}`, body)
  .then(resp => {
    dispatch(updateSceneGroupSuccess(resp))
    return Promise.resolve(resp);
  }).catch(error => {
    dispatch(updateSceneGroupFailure(error));
    return Promise.reject(error);
  })
}

const deleteSceneGroupSuccess = (resp) => {
  return {
    type: DELETE_SCENE_GROUP_SUCCESS,
    resp,
  }
}

const deleteSceneGroupFailure = (error) => {
  return {
    type: DELETE_SCENE_GROUP_FAILURE,
    error,
  }
}

export const deleteSceneGroup = (name) => (dispatch) => {
  dispatch(request());
  return api.delete(`/companies/${localStorage.getItem('companyId')}/sceneGroups/${name}`)
  .then(resp => {
    dispatch(deleteSceneGroupSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(deleteSceneGroupFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}
