import {
  LIST_SCENE_SUCCESS,
  LIST_SCENE_FAILURE,
  UPDATE_SCENE_SUCCESS,
  UPDATE_SCENE_FAILURE,
  CREATE_SCENE_SUCCESS,
  CREATE_SCENE_FAILURE,
  DELETE_SCENE_SUCCESS,
  DELETE_SCENE_FAILURE,
  REST_SCENE_LIST
} from "../actions/scene.actions";

const initialState = {
  items: [],
  error: "",
  page: 1,
  nextpageAvailable: false,
};

const scene = (state = initialState, action) => {
  switch (action.type) {
    case LIST_SCENE_SUCCESS:
      let newSceneList = state.items.concat(action.resp.data.scene)
      return {
        ...state,
        items: newSceneList,
        page: action.resp.data.page,
        nextpageAvailable: ((action.resp.data.pages - action.resp.data.page) <= 0) ? false : true,
      };
    case LIST_SCENE_FAILURE:
      return {
        ...state,
        items: [],
        error: action.error
      };
    case REST_SCENE_LIST:
      return {
        ...state,
        ...initialState
      }
    case CREATE_SCENE_SUCCESS:
      state.items.push(action.resp.data.scene)
      return {
        ...state,
        items: state.items,
      };
    case CREATE_SCENE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case UPDATE_SCENE_SUCCESS:
      state.items.map((i, index) => {
        if (i.id === action.resp.data.scene.id) {
          state.items[index] = action.resp.data.scene;
        }
      });
      return {
        ...state
      };
    case UPDATE_SCENE_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case DELETE_SCENE_SUCCESS:
      return {
        ...state
      };
    case DELETE_SCENE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
};

export default scene;
