import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";
import Swal from "sweetalert2";

export const ORGANISATION_DETAILS_SUCCESS = "ORGANISATION_DETAILS_SUCCESS";
export const ORGANISATION_DETAILS_FAILURE = "ORGANISATION_DETAILS_FAILURE";
export const SET_MULTI_FACTOR_AUTHENTICATION_SUCCESS =
  "SET_MULTI_FACTOR_AUTHENTICATION_SUCCESS";
export const SET_MULTI_FACTOR_AUTHENTICATION_FAILURE =
  "SET_MULTI_FACTOR_AUTHENTICATION_FAILURE";
export const GET_MULTI_FACTOR_AUTHENTICATION_SUCCESS =
  "GET_MULTI_FACTOR_AUTHENTICATION_SUCCESS";
export const GET_MULTI_FACTOR_AUTHENTICATION_FAILURE =
  "GET_MULTI_FACTOR_AUTHENTICATION_FAILURE";
export const SUBSCRIPTION_DETAILS_SUCCESS = "SUBSCRIPTION_DETAILS_SUCCESS";
export const SUBSCRIPTION_DETAILS_FAILURE = "SUBSCRIPTION_DETAILS_FAILURE";

// export const SET_SUBSCRIPTION_DETAILS_FAILURE = 'SET_SUBSCRIPTION_DETAILS_FAILURE';
// export const SET_SUBSCRIPTION_DETAILS_SUCCESS = 'SET_SUBSCRIPTION_DETAILS_SUCCESS';

export const SET_BILLING_SUCCESS = "SET_BILLING_SUCCESS";
export const SET_BILLING_FAILURE = "SET_BILLING_FAILURE";

export const INVOICE_DETAILS_SUCCESS = "INVOICE_DETAILS_SUCCESS";
export const INVOICE_DETAILS_FAILURE = "INVOICE_DETAILS_FAILURE";

const organizationDetailsSuccess = res => {
  return {
    type: ORGANISATION_DETAILS_SUCCESS,
    res
  };
};

const organizationDetailsFailure = error => {
  return {
    type: ORGANISATION_DETAILS_FAILURE,
    error
  };
};

export const getOrganizationDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/accounts`)
    .then(resp => {
      dispatch(organizationDetailsSuccess(resp.data.organization));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(organizationDetailsFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const setMultiFactorAuthenticationSuccess = res => {
  return {
    type: SET_MULTI_FACTOR_AUTHENTICATION_SUCCESS,
    res
  };
};

const setMultiFactorAuthenticationFailure = err => {
  return {
    type: SET_MULTI_FACTOR_AUTHENTICATION_FAILURE,
    err
  };
};

const getMultiFactorAuthenticationSuccess = res => {
  return {
    type: GET_MULTI_FACTOR_AUTHENTICATION_SUCCESS,
    res
  };
};

const getMultiFactorAuthenticationFailure = err => {
  return {
    type: GET_MULTI_FACTOR_AUTHENTICATION_FAILURE,
    err
  };
};

export const setMultiFactorAuthentication = param => dispatch => {
  dispatch(request());
  return api
    .post(`/companies/${localStorage.getItem("companyId")}/mfa`, param)
    .then(resp => {
      dispatch(setMultiFactorAuthenticationSuccess(resp));
      let updateMfa = {
        data: { mfa: { mfa: { sms: param.sms, email: param.email } } }
      };
      dispatch(getMultiFactorAuthenticationSuccess(updateMfa));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(setMultiFactorAuthenticationFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};
export const getMultiFactorAuthentication = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/mfa`)
    .then(resp => {
      dispatch(getMultiFactorAuthenticationSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getMultiFactorAuthenticationFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

// export const setSubsriptionDetailsSuccess = res => {
//     return {
//         type : SET_SUBSCRIPTION_DETAILS_SUCCESS,
//         res
//     }
// }

// export const setSubsriptionDetailsFailure = error => {
//     return {
//         type : SET_SUBSCRIPTION_DETAILS_FAILURE,
//         error
//     }
// }

// export const setSubscriptionDetails = (resp) => (dispatch) => {
//     dispatch(request());
//     dispatch(setSubsriptionDetailsSuccess(resp));
//     return api.get(`/subscriptionDetails/${localStorage.getItem('companyId')}`)
//     .then(resp => {
//         dispatch(subsriptionDetailsSuccess(resp));
//         return Promise.resolve(resp);
//     })
//     .catch(error => {
//         dispatch(subsriptionDetailsFailure(error));
//         return Promise.reject(error);
//     })
// };

const subsriptionDetailsSuccess = res => {
  return {
    type: SUBSCRIPTION_DETAILS_SUCCESS,
    subscription: res.subscription
  };
};

const subsriptionDetailsFailure = error => {
  return {
    type: SUBSCRIPTION_DETAILS_FAILURE,
    error
  };
};

export const getSubscriptionDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(
      `/companies/${localStorage.getItem("companyId")}/accounts/subscription`
    )
    .then(resp => {
      dispatch(subsriptionDetailsSuccess(resp.data));
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(subsriptionDetailsFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const getSubscriptionTrailDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(
      `/companies/${localStorage.getItem(
        "companyId"
      )}/accounts/subscription-detail`
    )
    .then(resp => {
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const setBillingSuccess = res => {
  return {
    type: SET_BILLING_SUCCESS,
    res
  };
};

const setBillingFailure = error => {
  return {
    type: SET_BILLING_FAILURE,
    error
  };
};

export const setBillingDetails = param => async dispatch => {
  dispatch(request());

  // await api.post(`/accounts/plans`);
  return await api
    .post(
      `/companies/${localStorage.getItem("companyId")}/accounts/billing`,
      param
    )
    .then(resp => {
      dispatch(setBillingSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(setBillingFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const invoiceDetailsSuccess = res => {
  return {
    type: INVOICE_DETAILS_SUCCESS,
    res
  };
};

const invoiceDetailsFailure = error => {
  return {
    type: INVOICE_DETAILS_FAILURE,
    error
  };
};

export const getInvoiceDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/accounts/invoice`)
    .then(resp => {
      dispatch(invoiceDetailsSuccess(resp.data));
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(invoiceDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};

export const downloadInvoicePdf = invoice_num => dispatch => {
  dispatch(request());
  return api
    .get(`/accounts/invoicepdf/${invoice_num}`)
    .then(resp => {
      dispatch(stopSpinner());
    })
    .catch(error => {
      dispatch(stopSpinner());
    });
};
