import {
  CREATE_IOT_HUB_SUCCESS,
  CREATE_IOT_HUB_FAILURE,
  LIST_IOT_HUBS_SUCCESS,
  LIST_IOT_HUBS_FAILURE,
  DELETE_IOT_HUB_SUCCESS,
  DELETE_IOT_HUB_FAILURE,
  UPDATE_IOT_HUBS_SUCCESS
} from "../actions/iotHub.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  iotHubs: []
};

const iotHub = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case CREATE_IOT_HUB_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case CREATE_IOT_HUB_FAILURE:
      return {
        ...state,
        pending: false
      };
    case LIST_IOT_HUBS_SUCCESS:
      return {
        ...state,
        iotHubs: action.resp.data.iotHubs,
        pending: false
      };
    case LIST_IOT_HUBS_FAILURE:
      return {
        ...state,
        pending: false
      };
    case DELETE_IOT_HUB_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case DELETE_IOT_HUB_FAILURE:
      return {
        ...state,
        pending: false
      };
    case UPDATE_IOT_HUBS_SUCCESS : 
      const iotHub = state.iotHubs.map(item=> {
        if(action.iot && item.name == action.iot.name) {
          return {
            ...item,
            connection_string : action.iot && action.iot.connection_string
          }
        }
        return item;
      })
      return {
        ...state,
        iotHubs : iotHub,
        pending : false
      }
    default:
      return state;
  }
};

export default iotHub;
