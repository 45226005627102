import {
	FAILED_SEARCH_RESULT,
	SEARCH_RESULT
} from "../actions/searchBar.actions";

const initialState = {
	search_result : {
		devices : [],
		locations : []
	}
}
const searchBar = (state = initialState, action) => {
	switch (action.type) {
		case FAILED_SEARCH_RESULT:
			return {
				...state,
				search_result: {
					devices: [],
					locations: []
				}
			}

		case SEARCH_RESULT:
			return {
				...state,
				search_result: action.result || {
					devices: [],
					locations : []
				}
			}
		default:
			return state;
	}
}
export default searchBar