import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";
import Swal from "sweetalert2";

export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAILURE = "USER_DETAILS_FAILURE";

const userDetailsSuccess = res => {
  return {
    type: USER_DETAILS_SUCCESS,
    res
  };
};

const userDetailsFailure = error => {
  return {
    type: USER_DETAILS_FAILURE,
    error
  };
};

export const getUserDetails = () => dispatch => {
  dispatch(request());
  return api
    .get(`/userInvitation/user/${localStorage.getItem("email")}`)
    .then(resp => {
      dispatch(userDetailsSuccess(resp.data));
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};

export const getInvitedUser = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/user/list/invited`)
    .then(resp => {
      // dispatch(userDetailsSuccess(resp.data));
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      //dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};

export const getActiveUser = () => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem("companyId")}/user/list/active`)
    .then(resp => {
      //dispatch(userDetailsSuccess(resp.data));
      dispatch(stopSpinner());

      return Promise.resolve(resp.data);
    })
    .catch(error => {
      //dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};

export const sendUserInivitaion = payload => dispatch => {
  dispatch(request());
  return api
    .post(`/companies/${localStorage.getItem("companyId")}/user/invitation/`, {
      ...payload
    })
    .then(resp => {
      dispatch(stopSpinner());
      Swal.fire({
        type: "success",
        title: resp.data.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};


export const sendUserReInivitaion = payload => dispatch => {
  dispatch(request());
  return api
    .post(`/companies/${localStorage.getItem("companyId")}/user/reinvitation/`, {
      ...payload
    })
    .then(resp => {
      dispatch(stopSpinner());
      Swal.fire({
        type: "success",
        title: resp.data.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};


export const revokeUserInivitaion = payload => dispatch => {
  dispatch(request());
  return api
    .post(`/companies/${localStorage.getItem("companyId")}/user/revoke/`, {
      ...payload
    })
    .then(resp => {
      // dispatch(stopSpinner());
      Swal.fire({
        type: "success",
        title: resp.data.message,
        showConfirmButton: false,
        timer: 1000
      });
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      Swal.fire({
        type: "error",
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
      return Promise.reject(error);
    });
};


export const updateUser = (payload) => dispatch => {
  dispatch(request());
  return api
    .put(`/user/${localStorage.getItem("userId")}`, {...payload})
    .then(resp => {
      dispatch(userDetailsSuccess(resp.data));
      dispatch(stopSpinner());
      return Promise.resolve(resp.data);
    })
    .catch(error => {
      dispatch(userDetailsFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};