import {
  GET_DEVICE_CONTENT_SUCCESS,
  GET_DEVICE_CONTENT_FAILURE,
  GET_CURRENT_DEVICE_CONTENT_SUCCESS,
  GET_CURRENT_DEVICE_CONTENT_FAILURE,
  UPDATE_DEVICE_CONTENT_SUCCESS,
  UPDATE_DEVICE_CONTENT_FAILURE,
  REFRESH_DEVICE_CONTENT_SUCCESS,
  REFRESH_DEVICE_CONTENT_FAILURE,
} from "../actions/content.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  items: [],
  deviceitem: {},
};

const contentGroups = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case GET_CURRENT_DEVICE_CONTENT_SUCCESS:
      action.resp.data.device.content.scene_grp.scenes.push(action.resp.data.defaultScene);
      return {
        ...state,
        deviceitem: action.resp.data.device,
        pending: false
      };
    case GET_CURRENT_DEVICE_CONTENT_FAILURE:
      return {
        ...state,
        pending: false
      };
    case GET_DEVICE_CONTENT_SUCCESS:
      return {
        ...state,
        items: action.resp.data.sceneGroups,
        pending: false
      };
    case GET_DEVICE_CONTENT_FAILURE:
      return {
        ...state,
        pending: false
      };
      case UPDATE_DEVICE_CONTENT_SUCCESS:
      const newSceneGrp = Object.assign({}, action.resp.data.device.scene_grp);
      const newSceneGrpRefresh = Object.assign({}, action.resp.data.device.refreshContent);

      state.deviceitem = {
        content: {
          scene_grp: {},
          refreshContent: {}
        }
      }
      Object.assign(state.deviceitem.content.scene_grp, newSceneGrp)
      Object.assign(state.deviceitem.content.refreshContent, newSceneGrpRefresh)

      return {
        ...state,
        pending: false
      };
    case UPDATE_DEVICE_CONTENT_FAILURE:
      return {
        ...state,
        pending: false
      };
    case REFRESH_DEVICE_CONTENT_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case REFRESH_DEVICE_CONTENT_FAILURE:
      return {
        ...state,
        pending: false
     };
    default:
      return state;
  }
};

export default contentGroups;
