import {
    DATA_LOADING_STATE,
    DATA_LOADED_STATE,
  } from '../actions/helper.actions';
  
  const initialState = {
    pending: false,
  }

  const spinner = (state = initialState, action) => {
    switch (action.type) {
      case DATA_LOADING_STATE:
        return {
          pending: true,
        };
      case DATA_LOADED_STATE:
        return {
          pending: false,
        };
      default:
        return state;
    }
  }
  export default spinner;