import { request, stopSpinner } from './helper.actions';
import { api } from './api-lib/api';


export const GET_FILTER_DEVICE_SUCCESS = 'GET_FILTER_DEVICE_SUCCESS';
export const GET_FILTER_DEVICE_FAILURE = 'GET_FILTER_DEVICE_FAILURE'; 
export const GET_DEVICE_SEARCH_SUCCESS = 'GET_DEVICE_SEARCH_SUCCESS'; 
export const GET_DEVICE_SEARCH_FAILURE = 'GET_DEVICE_SEARCH_FAILURE'; 
export const UNLIST_DEVICES_FOR_SEARCH_SUCCESS = 'UNLIST_DEVICES_FOR_SEARCH_SUCCESS';
export const SET_REDUX_STORE_FOR_DEVICE_SEARCH = 'SET_REDUX_STORE_FOR_DEVICE_SEARCH';

const getFilterDeviceSuccess = (resp, searchKey, searchBy, sortDevicesIn) => (
  {
    type: GET_FILTER_DEVICE_SUCCESS,
    resp,
    searchKey,
    searchBy,
    sortDevicesIn
  }
);

const getFilterDeviceFailure = error => (
  {
    type: GET_FILTER_DEVICE_FAILURE,
    error,
  }
);

export const getFilteredDevices = ({ searchKey, searchBy, pageCount, sortDevicesIn}) => (dispatch) => {
  dispatch(request());
  sortDevicesIn = sortDevicesIn ? sortDevicesIn : 'ascending'
  let queryString = `?sortDevicesIn=${sortDevicesIn}`;
  let pageNo='';

  if(searchBy && searchKey){
      queryString += `&searchBy=${searchBy}&searchKey=${searchKey}`;
  }
  

  if (pageCount) {
    pageNo = `&page=${pageCount}`;
  }

  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/search${queryString}${pageNo}`)
    .then(resp => {
      dispatch(getFilterDeviceSuccess(resp && resp.data, searchKey, searchBy, sortDevicesIn));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getFilterDeviceFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
    
};

const getDeviceSearchSuccess = (resp, searchKey, searchBy, sortDevicesIn ) => (
  {
    type: GET_DEVICE_SEARCH_SUCCESS,
    resp,
    searchKey,
    searchBy,
    sortDevicesIn,
  }
);

const getDeviceSearchFailure = error => (
  {
    type: GET_DEVICE_SEARCH_FAILURE,
    error,
  }
);

export const getDeviceSearch = ({ searchKey, searchBy, pageCount, sortDevicesIn}) => (dispatch) => {
  dispatch(request());
  sortDevicesIn = sortDevicesIn ? sortDevicesIn : 'ascending'
  let queryString = `?sortDevicesIn=${sortDevicesIn}`;
  let pageNo='';

  if(searchBy && searchKey){
      queryString += `&searchBy=${searchBy}&searchKey=${searchKey}`;
  }

  if (pageCount) {
    pageNo = `&page=${pageCount}`;
  }

  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/search${queryString}${pageNo}`)
    .then(resp => {
      dispatch(getDeviceSearchSuccess(resp && resp.data, searchKey, searchBy, sortDevicesIn));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getDeviceSearchFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
    
};

export const setStoreDevicesFromSearch = ({ searchKey, searchBy, pageCount, sortDevicesIn}) => (dispatch) => {
  dispatch({
    type: SET_REDUX_STORE_FOR_DEVICE_SEARCH,
    pageCount,
    searchKey,
    searchBy,
    sortDevicesIn,
  });
}

export const unListDeviceForSearch = (resp) => (dispatch) => {
  dispatch({
    type: UNLIST_DEVICES_FOR_SEARCH_SUCCESS,
    resp,
  })
}


