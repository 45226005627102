import {
    HANDLE_F_PASSWORD_MODAL,
  } from '../actions/forgotpassword.actions';
  import {
    API_REQUEST
  } from '../actions/helper.actions';
  
  const initialState = {
    pending: false,
    isregisterModalOpen: false,
  }
  
  const forgotPassword = (state = initialState, action) => {
    switch(action.type){
      case HANDLE_F_PASSWORD_MODAL:
        return {
            ...state,
            isFPasswordModalOpen: action.flag
        }
      case API_REQUEST:
        return{
            ...state,
            pending: true,
        }
      default:
        return state
    }
  }
  export default forgotPassword;