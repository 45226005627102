import { api } from './api-lib/api';
import {
  request,
  stopSpinner
} from './helper.actions';
import {
  storeEmailToken
} from './signIn.actions';

export const HANDLE_REGISTER_MODAL = 'HANDLE_REGISTER_MODAL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

export const handleRegisterModal = (flag) => ({
  type: HANDLE_REGISTER_MODAL,
  flag
});

const registerSuccess = resp => (
  {
    type: REGISTER_SUCCESS,
    resp,
  }
);

const registerFailure = error => (
  {
    type: REGISTER_FAILURE,
    error,
  }
);

export const register = payload => (dispatch) => {
  dispatch(request())
  return api.post('/register', { ...payload })
    .then(resp => {
      dispatch(registerSuccess(resp));
      dispatch(storeEmailToken());
     dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => { 
        dispatch(registerFailure(error));
        dispatch(stopSpinner());
        return Promise.reject(error); 
    });
};