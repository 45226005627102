import {
    CREATE_FOLDER_FAILURE,
    CREATE_FOLDER_SUCCESS,
    GET_FOLDER_FAILURE,
    GET_FOLDER_SUCCESS
  } from '../actions/folder.actions';
  import {
    API_REQUEST
  } from '../actions/helper.actions';
  
  const initialState = {
    pending: false,
    folders: [],
  };
  
  const folder = (state = initialState, action) => {
    switch(action.type){
        case CREATE_FOLDER_SUCCESS:
            return {
                ...state,
                pending: false,
            };
        case CREATE_FOLDER_FAILURE:
            return{
                ...state,
                pending: false,
                folders: []
            };
        case GET_FOLDER_SUCCESS:
            console.log('action', action);
            
            return {
                ...state,
                pending: false,
                folders: action.folder
            };
        case GET_FOLDER_FAILURE:
            return{
                ...state,
                pending: false 
            };
      default:
        return state
    }
  }
  export default folder;