import { combineReducers } from 'redux';
import signIn from './signIn.reducers';
import alert from './alert.reducers';
import register from './register.reducers';
import forgot from './forgot.reducers';
import helper from './helper.reducers';
import device from './devices.reducers';
import location from './location.reducers';
import config from './deviceConfig.reducers';
import deviceAlert from './deviceAlert.reducers';
import deviceSoftware from './deviceSoftware.reducers';
import scene from './scene.reducers';
import sceneGroups from './sceneGroups.reducers';
import contentGroups from './content.reducers';
import account from './account.reducers';
import userInvitation from './userInvitation.reducers';
import settings from './settings.reducers';
import spinner from './spinner.reducers';
import iotHub from './iotHub.reducers';
import screenShot from './screenShot.reducers';
import folder from './folder.reduce';
import tags from './tag.reducer';
import filtered from './filtered.reducers';
import searchBar from './searchBar.reducer';

export const appReducer = combineReducers({
  signIn,
  alert,
  register,
  helper,
  device,
  location,
  config,
  deviceAlert,
  deviceSoftware,
  scene,
  sceneGroups,
  contentGroups,
  account,
  userInvitation,
  settings,
  forgot,
  spinner,
  iotHub,
  folder,
  tags,
  screenShot,
  filtered,
  searchBar
 });

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER'){
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action)
};