import {
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  TOKEN_EMAIL_TO_STORE,
  HADLE_SIGNIN_MODAL,
  HADLE_REQUEST_DEMO_MODAL,
  LOGOUT_USER,
  NAVIGATION_FAILIUR,
  NAVIGATION_SUCCESS
} from '../actions/signIn.actions';
import {
  API_REQUEST
} from '../actions/helper.actions';

const initialState = {
  pending: false,
  token: '',
  companyName: '',
  isSignInModalOpen: false,
  navigation:'111'
}

const signIn = (state = initialState, action) => {

  switch(action.type){
    case HADLE_SIGNIN_MODAL:
    return {
      ...state,
      isSignInModalOpen: action.flag
    }
    case API_REQUEST:
    return{
      ...state,
      pending: true,
    }
    case TOKEN_EMAIL_TO_STORE:
        
    return {
      ...state,
      token: localStorage.getItem('token'),
      companyName: localStorage.getItem('companyName'),
      userName: localStorage.getItem('userName')
      
    }
    case SIGNIN_SUCCESS:
    return{
      ...state,
      pending: false,
    }
    case SIGNIN_FAILURE:
    return{
      ...state,
      pending: false,
    }
    case NAVIGATION_SUCCESS:
      
    return{
      ...state,
      navigation : action.resp.data.data,
      pending: false,
    }
    case NAVIGATION_FAILIUR:
    return{
      ...state,
      pending: false,
    }
    case HADLE_REQUEST_DEMO_MODAL:
    return {
      ...state,
      isrequestDemoModalOpen: action.flag
    }
    default:
    return state
  }
}

export default signIn