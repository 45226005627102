import {
  LIST_LOCATION_SUCCESS,
  UNLIST_LOCATION_SUCCESS,
  LIST_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  UPDATE_LOCATION_FAILURE,
  GET_LOCATION_SUCCESS,
  GET_LOCATION_FAILURE,
  CREATE_LOCATION_SUCCESS,
  CREATE_LOCATION_FAILURE,
  ASSIGN_LOCATION_SUCCESS,
  ASSIGN_LOCATION_FAILURE,
  LIST_LOCATION_WITH_DEVICE_COUNT_SUCCESS,
  LIST_LOCATION_WITH_DEVICE_COUNT_FAILURE,
  GET_LOCATION_DEVICES_WITH_SCREENSHOT_SUCCESS,
  GET_LOCATION_DEVICES_WITH_SCREENSHOT_FAILURE,
  CLEAR_LOCATION_DEVICES_LIST_TABLE
} from "../actions/location.actions";

const initialState = {
  items: [],
  locationListWithDeviceCount: [],
  error: "",
  location: {},
  nextPageAvailable: false,
  nextPageAvailableForLocationWithDeviceCount: false,
  pageCountLocation: 1,
  pageCountForLocationWithDeviceCount: 1,
  locationDevicesScreenshotTable:{
    page: 1,
    totalPages: 0,
    devices: [],
    nextPageAvailable: false
  }
};

const getLocationDevicesScreenshotNewState = ({ action, state }) => {
  const { metadata: { page, totalPages }, list } = action.resp.location.devices;
  const arrCopy = JSON.parse(JSON.stringify(state.locationDevicesScreenshotTable.devices));
  const newArr = arrCopy.concat(list);
  
  return {
    ...state,
    location: action.resp.location,
    locationDevicesScreenshotTable:{
      ...state.locationDevicesScreenshotTable,
      devices: newArr,
      page,
      totalPages,
      nextPageAvailable: (page < totalPages) ? true : false
    }
  }
}


const location = (state = initialState, action) => {
  switch (action.type) {
    case LIST_LOCATION_SUCCESS:
      let newLocationArr = []
      let arrLocationCopy = []
      arrLocationCopy = JSON.parse(JSON.stringify(state.items));
      newLocationArr = arrLocationCopy.concat(action.resp.data.locations);
      return {
        ...state,
        items: newLocationArr,
        nextPageAvailable: ((action.resp.data.pages - action.resp.data.page) <= 0) ? false : true,
        pageCountLocation: action.resp.data.page,
      };
    case LIST_LOCATION_WITH_DEVICE_COUNT_SUCCESS:
      let newArr = []
      let arrCopy = []
      arrCopy = JSON.parse(JSON.stringify(state.locationListWithDeviceCount));
      newArr = arrCopy.concat(action.resp.data.locations.list);
      return {
        ...state,
        locationListWithDeviceCount: newArr,
        nextPageAvailableForLocationWithDeviceCount: ((action.resp.data.locations.metadata.totalPages - action.resp.data.locations.metadata.page) <= 0) ? false : true,
        pageCountForLocationWithDeviceCount: action.resp.data.locations.metadata.page,
      };
    case LIST_LOCATION_WITH_DEVICE_COUNT_FAILURE:
      return {
        ...state,
        items: [],
        error: action.error
      };
    case UNLIST_LOCATION_SUCCESS:
      return {
        ...state,
        ...initialState
      };
    case LIST_LOCATION_FAILURE:
      return {
        ...state,
        items: [],
        error: action.error
      };
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
      };
    case UPDATE_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case GET_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.resp.data.location
      };
    case GET_LOCATION_FAILURE:
      return {
        ...state,
        location: {}
      };
    case CREATE_LOCATION_SUCCESS:
      const newArrCopy = JSON.parse(JSON.stringify(state.items));
      newArrCopy.unshift(action.resp.data.location);
      return {
        ...state,
        items: newArrCopy,
        pending: false,
      }
    case CREATE_LOCATION_FAILURE:
      return {
        ...state,
        pending: false,
      }
    case ASSIGN_LOCATION_SUCCESS:
      return {
        ...state,
      };
    case ASSIGN_LOCATION_FAILURE:
      return {
        ...state,
        error: action.error
      };
    case GET_LOCATION_DEVICES_WITH_SCREENSHOT_SUCCESS:
      
      return getLocationDevicesScreenshotNewState({ action, state })
    case GET_LOCATION_DEVICES_WITH_SCREENSHOT_FAILURE:
      return {
        ...state,
      }
    case CLEAR_LOCATION_DEVICES_LIST_TABLE:
      return {
        ...state,
        locationDevicesScreenshotTable: {
          ...state.locationDevicesScreenshotTable,
          ...initialState.locationDevicesScreenshotTable
        }
      }
    default:
      return state;
  }
};

export default location;
