import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const TOKEN_EMAIL_TO_STORE = "TOKEN_EMAIL_TO_STORE";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const HADLE_SIGNIN_MODAL = "HADLE_SIGNIN_MODAL";
export const LOGOUT_USER = "LOGOUT_USER";
export const NAVIGATION_SUCCESS = "NAVIGATION_SUCCESS";
export const NAVIGATION_FAILIUR = "NAVIGATION_FAILIUR";
export const HADLE_REQUEST_DEMO_MODAL = "HADLE_REQUEST_DEMO_MODAL";

const signInSuccess = resp => ({
  type: SIGNIN_SUCCESS,
  resp
});

const signInFailure = error => ({
  type: SIGNIN_FAILURE,
  error
});

export const navigationSuccess = resp => ({
  type: NAVIGATION_SUCCESS,
  resp
});

export const navigationFailiur = error => ({
  type: NAVIGATION_FAILIUR,
  error
});


export const handleRequestDemoModal = (flag) => ({
  type: HADLE_REQUEST_DEMO_MODAL,
  flag
})

export const signIn = payload => dispatch => {
  dispatch(request());
  return api
    .post("/signIn", { ...payload })
    .then(resp => {
      dispatch(signInSuccess(resp));
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("companyId", resp.data.user.company.company_id);
      localStorage.setItem("companyName", resp.data.user.company.name);
      localStorage.setItem("email", resp.data.user.email);
      localStorage.setItem("userId", resp.data.user.userId);
      localStorage.setItem("userRole", resp.data.user.userRole);
      localStorage.setItem("userName", resp.data.user.first_name);
      localStorage.setItem("lastName", resp.data.user.last_name);
      localStorage.setItem("mobile", resp.data.user.mobile_number);
      localStorage.setItem("isTrailInfoShow", false);
      dispatch(storeEmailToken());
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(signInFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const signInMfa = payload => dispatch => {
  dispatch(request());
  return api
    .post("/verify-otp", { ...payload })
    .then(resp => {
      dispatch(signInSuccess(resp));
      localStorage.setItem("token", resp.data.token);
      localStorage.setItem("companyId", resp.data.user.company.company_id);
      localStorage.setItem("companyName", resp.data.user.company.name);
      localStorage.setItem("email", resp.data.user.email);
      localStorage.setItem("userRole", resp.data.user.userRole);
      localStorage.setItem("userName", resp.data.user.first_name);
      localStorage.setItem("lastName", resp.data.user.last_name);
      localStorage.setItem("userId", resp.data.user.userId);
      localStorage.setItem("mobile", resp.data.user.mobile_number);
      localStorage.setItem("isTrailInfoShow", false);
      dispatch(storeEmailToken());
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(signInFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const storeEmailToken = () => ({
  type: TOKEN_EMAIL_TO_STORE
});

export const handleSignInModal = flag => ({
  type: HADLE_SIGNIN_MODAL,
  flag
});

export const resetPassword = payload => dispatch => {
  dispatch(request());
  return api
    .post("/user/update_password", { ...payload })
    .then(resp => {
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(signInFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const requestDemo = payload => dispatch => {
  dispatch(request());
  return api
    .post("/request-demo", { ...payload })
    .then(resp => {
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(signInFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const requestMailSubscribe = payload => dispatch => {
   
  dispatch(request());
  return api
    .post("/mail-subscribe", { ...payload })
    .then(resp => {
      dispatch(stopSpinner());      
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const changePassword = payload => dispatch => {
  dispatch(request());
  return api
    .post("/reset-password", { ...payload })
    .then(resp => {
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(stopSpinner());
      dispatch(signInFailure(error));
      return Promise.reject(error);
    });
};

export const getNavigationApi = () => dispatch => {
  dispatch(request());
  return api
    .get("/user/allowed/component")
    .then(resp => {
      dispatch(navigationSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(navigationFailiur(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};
export const getVerifyAccount = verifyToken => dispatch => {
  dispatch(request());
  return api
    .post("/verifyAccount", { verifyToken })
    .then(resp => {
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};


export const userStatus = email => dispatch => {
  //dispatch(request());
  return api
    .post("/user/status", { email })
    .then(resp => {
     // dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
     // dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const logoutUser = () => ({
  type: LOGOUT_USER
});
