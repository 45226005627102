import { api } from './api-lib/api';
import { request, stopSpinner } from './helper.actions';
import { getLocationByName } from './location.actions';

export const GET_DEVICES = 'GET_DEVICES';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILURE = 'GET_DEVICES_FAILURE';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAILURE = 'GET_DEVICE_FAILURE';
export const UPDATE_DEVICE_STATUS_SUCCESS = 'UPDATE_DEVICE_STATUS_SUCCESS';
export const UPDATE_DEVICE_STATUS_FAILURE = 'UPDATE_DEVICE_STATUS_FAILURE';
export const GET_UNREGISTERED_DEVICES_SUCCESS = 'GET_UNREGISTERED_DEVICES_SUCCESS';
export const GET_UNREGISTERED_DEVICES_FAILURE = 'GET_UNREGISTERED_DEVICES_FAILURE';
export const UPDATE_UNREGISTERED_DEVICE_SUCCESS = 'UPDATE_UNREGISTERED_DEVICE_SUCCESS';
export const UPDATE_UNREGISTERED_DEVICE_FAILURE = 'UPDATE_UNREGISTERED_DEVICE_FAILURE';
export const UPDATE_DEVICE_NAME_SUCCESS = 'UPDATE_DEVICE_NAME_SUCCESS';
export const UPDATE_DEVICE_NAME_FAILURE = 'UPDATE_DEVICE_NAME_FAILURE';
export const CREATE_UNREGISTER_DEVICE_SUCCESS = 'CREATE_UNREGISTER_DEVICE_SUCCESS';
export const CREATE_UNREGISTER_DEVICE_FAILURE = 'CREATE_UNREGISTER_DEVICE_FAILURE';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILURE = 'DELETE_DEVICE_FAILURE';
export const GET_DEVICE_CPU_USAGES_SUCCESS = 'GET_DEVICE_CPU_USAGES_SUCCESS';
export const GET_DEVICE_CPU_USAGES_FAILURE = 'GET_DEVICE_CPU_USAGES_FAILURE';
export const GET_SCREEN_SHOT_SUCCESS = 'GET_SCREEN_SHOT_SUCCESS';
export const GET_SCREEN_SHOT_FAILURE = 'GET_SCREEN_SHOT_FAILURE';
export const CREATE_BULK_DEVICES_SUCCESS = 'CREATE_BULK_DEVICES_SUCCESS';
export const CREATE_BULK_DEVICES_FAILURE = 'CREATE_BULK_DEVICES_FAILURE';
export const DASHBOARD_FILTER_DEVICE_SUCCESS = 'DASHBOARD_FILTER_DEVICE_SUCCESS';
export const DASHBOARD_FILTER_DEVICE_FAILURE = 'DASHBOARD_FILTER_DEVICE_FAILURE';
export const UNLIST_DEVICES_FOR_FILTER_SUCCESS = 'UNLIST_DEVICES_FOR_FILTER_SUCCESS';
export const UNLIST_UNREGISTERED_DEVICES_SUCCESS = 'UNLIST_UNREGISTERED_DEVICES_SUCCESS';


const getDevicesSuccess = resp => (
  {
    type: GET_DEVICES_SUCCESS,
    resp,
  }
);

const getDevicesFailure = error => (
  {
    type: GET_DEVICES_FAILURE,
    error,
  }
);

const getDeviceSuccess = resp => (
  {
    type: GET_DEVICE_SUCCESS,
    resp,
  }
);

const getDeviceFailure = error => (
  {
    type: GET_DEVICE_FAILURE,
    error,
  }
);

const updateDeviceStatusSuccess = resp => (
  {
    type: UPDATE_DEVICE_STATUS_SUCCESS,
    resp,
  }
);

const updateDeviceStatusFailure = error => (
  {
    type: UPDATE_DEVICE_STATUS_FAILURE,
    error,
  }
)

export const getDevices = payload => (dispatch) => {
  dispatch(request())
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices?page=${payload}`)
    .then(resp => {
      dispatch(getDevicesSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getDevicesFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

export const getdeviceByName = (deviceName) => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}`)
  .then(resp => {
    dispatch(getDeviceSuccess(resp))
    dispatch(stopSpinner());
  })
  .catch(error => {
    dispatch(getDeviceFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

export const updateDeviceStatus = (deviceName, enable) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/status`, { device: { enable } })
    .then(resp => {
      dispatch(updateDeviceStatusSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(updateDeviceStatusFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

const getUnregisteredDevicesSuccess = resp => (
  {
    type: GET_UNREGISTERED_DEVICES_SUCCESS,
    resp,
  }
);

const getUnregisteredDevicesFailure = error => (
  {
    type: GET_UNREGISTERED_DEVICES_FAILURE,
    error,
  }
);

export const listUnregisteredDevices = (  hidespinner, page ) => (dispatch) => {
  dispatch(request(hidespinner));

  return api.get(`/companies/${localStorage.getItem('companyId')}/devices?unregisteredDevice=true&page=${page}`)
   .then(resp => {
      dispatch(getUnregisteredDevicesSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getUnregisteredDevicesFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
}

const updateUnregisteredDeviceSuccess = resp => (
  {
    type: UPDATE_UNREGISTERED_DEVICE_SUCCESS,
    resp,
  }
);

const  updateUnregisteredDeviceFailure = error => (
  {
    type: UPDATE_UNREGISTERED_DEVICE_FAILURE,
    error,
  }
)

export const updateUnregisteredDevice = (deviceName, deviceObj) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}?unregisteredDevice=true`, { device: deviceObj })
    .then(resp => {
      dispatch(updateUnregisteredDeviceSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(updateUnregisteredDeviceFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

const updateDeviceSuccess = resp => {
  return {
    type: UPDATE_DEVICE_NAME_SUCCESS,
    resp,
  }
}

const updateDeviceFailure = error => {
 return {
    type: UPDATE_DEVICE_NAME_FAILURE,
    error,
  }
}

export const updateDevice = (deviceObj, deviceName) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}`, { device: deviceObj })
  .then(resp => {
    dispatch(updateDeviceSuccess(resp))
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(updateDeviceFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  });
}

const createUnregisterDeviceSuccess = resp => (
  {
    type: CREATE_UNREGISTER_DEVICE_SUCCESS,
    resp,
  }
);

const  createUnregisterDeviceFailure = error => (
  {
    type: CREATE_UNREGISTER_DEVICE_FAILURE,
    error,
  }
)

export const createUnregisterDevice = (deviceObj) => (dispatch) => {
  dispatch(request());
  return api.post(`/companies/${localStorage.getItem('companyId')}/devices`, { device: deviceObj })
    .then(resp => {
      dispatch(createUnregisterDeviceSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(stopSpinner());
      dispatch(createUnregisterDeviceFailure(error));
      return Promise.reject(error);
    })
};

const deleteDeviceSuccess = resp => {
  return {
    type: DELETE_DEVICE_SUCCESS,
    resp,
  }
}

const deleteDeviceFailure = error => {
 return {
    type: DELETE_DEVICE_FAILURE,
    error,
  }
}

export const deleteDevice = (deviceId) => (dispatch) => {
  dispatch(request());
  return api.delete(`/companies/${localStorage.getItem('companyId')}/devices/${deviceId}`)
    .then(resp => {
      dispatch(deleteDeviceSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(deleteDeviceFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

const getDeviceCpuMemUsageSuccess = resp => (
  {
    type: GET_DEVICE_CPU_USAGES_SUCCESS,
    resp,
  }
);

const getDeviceCpuMemUsageFailure = error => (
  {
    type: GET_DEVICE_CPU_USAGES_FAILURE,
    error,
  }
);

export const getDeviceCpuMemUsage = (deviceName) => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/deviceMonitoring`)
    .then(resp => {
      dispatch(getDeviceCpuMemUsageSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(getDeviceCpuMemUsageFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
}

const createBulkDevicesSuccess = resp => (
  {
    type: CREATE_BULK_DEVICES_SUCCESS,
    resp,
  }
 );

 const  createBulkDevicesFailure = error => (
  {
    type: CREATE_BULK_DEVICES_FAILURE,
    error,
  }
 )

 export const createBulkDevices = (deviceObj, hidespinner) => (dispatch) => {
  dispatch(request(hidespinner));
  return api.post(`/companies/${localStorage.getItem('companyId')}/devices/bulk`, { device: deviceObj })
    .then(resp => {
      dispatch(createBulkDevicesSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(createBulkDevicesFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
 };

 const getScreenShotSuccess = resp => (
  {
    type: GET_DEVICE_CPU_USAGES_SUCCESS,
    resp,
  }
 );


 const getScreenShotFailure = error => (
  {
    type: GET_DEVICE_CPU_USAGES_FAILURE,
    error,
  }
 );


//  export const getScreenShot = (deviceName) => (dispatch) => {
//   dispatch(request());
//   return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/media`)
//     .then(resp => {
//       dispatch(getScreenShotSuccess(resp));
//       dispatch(stopSpinner());
//       return Promise.resolve(resp);
//     })
//     .catch(error => {
//       dispatch(getScreenShotFailure(error));
//       dispatch(stopSpinner());
//       return Promise.reject(error);
//     })
//  }



//  export const getDeviceMedia = (deviceName) => (dispatch) => {
//   dispatch(request());
//   return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/media`)
//     .then(resp => {
//       // dispatch(getDeviceMediaSuccess(resp));
//       dispatch(stopSpinner());
//       return Promise.resolve(resp);
//     })
//     .catch(error => {
//       // dispatch(getDeviceMediaFailure(error));
//       dispatch(stopSpinner());
//       return Promise.reject(error);
//     })
//  }

const filterDeviceSucess = resp => (
  {
    type: DASHBOARD_FILTER_DEVICE_SUCCESS,
    resp,
  }
);

const filterDeivceFailer = error => (
  {
    type: DASHBOARD_FILTER_DEVICE_FAILURE,
    error,
  }
);

export const filterDevices = (filterObj, page) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/filter?page=${page}`, filterObj)
    .then(resp => {
      dispatch(filterDeviceSucess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(filterDeivceFailer(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
}

export const unListDeviceForFilter = (resp) => (dispatch) => {
  dispatch({
    type: UNLIST_DEVICES_FOR_FILTER_SUCCESS,
    resp,
  })
}

export const unListUnregisteredDevices = (resp) => (dispatch) => {
  dispatch({
    type: UNLIST_UNREGISTERED_DEVICES_SUCCESS,
    resp,
  })
}

export const updateDeviceName = (payload) => {
  return api.post(`/company/batch/operations`, payload)
    .then(resp => {
      return Promise.resolve(resp);
    })
    .catch(error => {
      return Promise.reject(error);
    })
}
