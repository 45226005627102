import {
  GET_DEVICE_ALERTS_SUCCESS,
  GET_DEVICE_ALERTS_FAILURE
} from "../actions/deviceAlerts.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  items: []
};

const deviceAlert = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case GET_DEVICE_ALERTS_SUCCESS:
      return {
        ...state,
        items: action.resp,
        pending: false
      };
    case GET_DEVICE_ALERTS_FAILURE:
      return {
        ...state,
        pending: false
      };
    default:
      return state;
  }
};

export default deviceAlert;
