import {
  GET_DEVICE_SOFTWARE_SUCCESS,
  GET_DEVICE_SOFTWARE_FAILURE,
  UPDATE_DEVICE_SOFTWARE_SUCCESS,
  UPDATE_DEVICE_SOFTWARE_FAILURE
} from "../actions/deviceSoftware.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  items: {}
};

const deviceSoftware = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case GET_DEVICE_SOFTWARE_SUCCESS:
      return {
        ...state,
        items: action.resp,
        pending: false
      };
    case GET_DEVICE_SOFTWARE_FAILURE:
      return {
        ...state,
        pending: false
      };
    case UPDATE_DEVICE_SOFTWARE_SUCCESS:
      return {
        ...state,
        items: action.resp,
        pending: false
      };
    case UPDATE_DEVICE_SOFTWARE_FAILURE:
      return {
        ...state,
        pending: false
      };
    default:
      return state;
  }
};

export default deviceSoftware;
