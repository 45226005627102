import React, { Component } from 'react';
import { HashRouter, Route, Switch, withRouter } from 'react-router-dom';
import Interceptor from './interceptor';
import { connect } from 'react-redux';
import {  toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { StripeProvider } from 'react-stripe-elements';
import './App.scss';
 import {
  storeEmailToken,
  logoutUser,
} from './actions/signIn.actions';

import Popper from 'popper.js';
const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const SshWrapper = React.lazy(() => import('./components/SshWrapper'));
const LoadingSpinner = React.lazy(() => import('./components/Loader'));

toast.configure({
  position: "top-right",
  newestOnTop: true,
  transition: Slide,
  autoClose: 7000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
})


class App extends Component {

  componentDidMount() {
    const { storeEmailToken } = this.props;
    storeEmailToken();
    this.handleUnathError = this.handleUnathError.bind(this);
    Interceptor.interceptHandler(Interceptor, this.handleUnathError);
  }

  handleUnathError(resp) {
    const {
      logoutUser,
    } = this.props;
   
    logoutUser();
  }

  render() {
    const { pending } = this.props.spinner;
    return (
      // <StripeProvider apiKey="pk_test_Ca1GpHo4P6PtRJfNJL7KalIb00IMmd8EFB">
        <HashRouter>
			<React.Suspense fallback={loading()}>
        <div > 
					<Switch>
						<Route exact path="/ssh/:deviceId" name="ssh" render={props => <SshWrapper {...props} />} />
						<Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
					</Switch>
         <LoadingSpinner />  
				</div>
			</React.Suspense>
        </HashRouter>
      // </StripeProvider>  
    );
  }
}

const mapStateToProps = state => {
  let {pending} = state.spinner;
  return {...state, pending};
}

const actions = {
  storeEmailToken,
  logoutUser,
}

export default withRouter(connect(mapStateToProps, actions)(App));
