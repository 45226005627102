import {
  SCROLL_PAGE, MODAL_DISPLAY, RESET_PASSWORD_FIELD
} from '../actions/helper.actions';

const initialState = {
  domReference : '',
  modalReference: '',
  resetPasswordFieldFn: () => {}
}

const helper = (state = initialState, action) => {
  switch (action.type) {
    case SCROLL_PAGE:
    return{
      ...state,
      domReference: action.domReference
    }
    case MODAL_DISPLAY:
    return{
      ...state,
      modalReference: action.flag
    }
    case RESET_PASSWORD_FIELD:
      return {
        ...state,
        resetPasswordFieldFn: action.resetPasswordFieldFn
      }
    default:
    return state;
  }
}
export default helper;
