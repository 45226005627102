import {
    ORGANISATION_DETAILS_SUCCESS,
    ORGANISATION_DETAILS_FAILURE,
    SUBSCRIPTION_DETAILS_SUCCESS,
    SUBSCRIPTION_DETAILS_FAILURE,
    // SET_SUBSCRIPTION_DETAILS_FAILURE,
    // SET_SUBSCRIPTION_DETAILS_SUCCESS,
    SET_BILLING_SUCCESS,
    SET_BILLING_FAILURE,
    INVOICE_DETAILS_SUCCESS,
    INVOICE_DETAILS_FAILURE,
    SET_MULTI_FACTOR_AUTHENTICATION_SUCCESS,
    SET_MULTI_FACTOR_AUTHENTICATION_FAILURE,
    GET_MULTI_FACTOR_AUTHENTICATION_FAILURE,
    GET_MULTI_FACTOR_AUTHENTICATION_SUCCESS
} from "../actions/account.actions";

import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
    organization: {},
    subscription: {},
    billing: {},
    invoice: [],
    pending: false
};


const account = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return {
                ...state,
                pending: true
            };
        case ORGANISATION_DETAILS_SUCCESS:
            return {
                ...state,
                organization: action.res,
                pending: false,

            };
        case ORGANISATION_DETAILS_FAILURE:
            return {
                ...state,
                pending: false,

            }
        case SUBSCRIPTION_DETAILS_SUCCESS:
            return {
                ...state,
                subscription: action.subscription,
                pending: false,

            };
        case SUBSCRIPTION_DETAILS_FAILURE:
            return {
                ...state,
                subscription: {},
                pending: false,

            };
        // case SET_SUBSCRIPTION_DETAILS_SUCCESS:
        //     return {
        //         ...state,
        //         subscription: account.res,
        //         pending: false,

        //     }
        // case SET_SUBSCRIPTION_DETAILS_FAILURE:
        //     return {
        //         ...state,
        //         pending: false,
        //     }
        case SET_MULTI_FACTOR_AUTHENTICATION_SUCCESS:
            return {
                ...state,
                pending: false,

            }
        case SET_MULTI_FACTOR_AUTHENTICATION_FAILURE:
            return {
                ...state,
                pending: false,
            }
        case GET_MULTI_FACTOR_AUTHENTICATION_SUCCESS:

            return {
                ...state,
                organization: action.res.data.mfa,
                pending: false,

            }
        case GET_MULTI_FACTOR_AUTHENTICATION_FAILURE:
            return {
                ...state,
                pending: false,

            }
        case SET_BILLING_SUCCESS:
            return {
                ...state,
                pending: false,
            }
        case SET_BILLING_FAILURE:
            return {
                ...state,
                pending: false,
            }
        case INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                invoice: action.res.Invoice.data,
                pending: false
            }
        case INVOICE_DETAILS_FAILURE:
            return {
                ...state,
                invoice: [],
                pending: false
            }
        default:
            return state;
    }
}


export default account;