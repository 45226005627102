import {
    USER_DETAILS_SUCCESS, 
    USER_DETAILS_FAILURE
} from "../actions/userInvitation.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
    user: [],
    invitation: {}
};


const userInvitation = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return {
                ...state,
                pending: true
            };
        case USER_DETAILS_SUCCESS: 
            return {
                ...state,
                user : action.res.user,
                pending: false
            }
        case USER_DETAILS_FAILURE: 
            return {
                ...state,
                user : [],
                pending: false
            }
        default:
            return state;
    }
}


export default userInvitation;