import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const FAILED_SEARCH_RESULT = "FAILED_SEARCH_RESULT";
export const SEARCH_RESULT = "SEARCH_RESULT";

const getSearchResultFailed = res => {
  return {
    type: FAILED_SEARCH_RESULT
  };
};
const getSearchResultSuccess = res => {
  return {
    type: SEARCH_RESULT,
    result : res.data.result
  };
};

export const getSearchResult = (searchKey) => dispatch => {
	return api
		.get(`/companies/${localStorage.getItem("companyId")}/devices/keybased-search?sortDevicesIn=ascending&searchKey=${searchKey}`)
		.then(resp => {
			dispatch(getSearchResultSuccess(resp));
			return Promise.resolve(resp);
		})
		.catch(error => {
			dispatch(getSearchResultFailed(error));
			return Promise.reject(error);
		});
};
  