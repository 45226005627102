import { api } from './api-lib/api';
import { request, stopSpinner } from './helper.actions';

export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILURE = 'GET_CONFIG_FAILURE';
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILURE = 'UPDATE_CONFIG_FAILURE';
export const UPDATE_GLOBAL_CONFIG_SUCCESS = 'UPDATE_GLOBAL_CONFIG_SUCCESS';
export const UPDATE_GLOBAL_CONFIG_FAILURE = 'UPDATE_GLOBAL_CONFIG_FAILURE';

const getConfigSuccess = resp => (
  {
    type: GET_CONFIG_SUCCESS,
    resp,
  }
);

const getConfigFailure= error => (
  {
    type: GET_CONFIG_FAILURE,
    error,
  }
);

const updateGlobalConfigSuccess = resp => (
  {
    type: UPDATE_GLOBAL_CONFIG_SUCCESS,
    resp,
  }
);

const updateGlobalConfigFailure = error => (
  {
    type: UPDATE_GLOBAL_CONFIG_FAILURE,
    error,
  }
);

const updateConfigSuccess = resp => (
  {
    type: UPDATE_CONFIG_SUCCESS,
    resp,
  }
);

const updateConfigFailure = error => (
  {
    type: UPDATE_CONFIG_FAILURE,
    error,
  }
);

export const getdeviceConfigByName = (deviceName) => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/config`)
  .then(resp => {
    dispatch(getConfigSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(getConfigFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}

export const updateConfigByName = (formInput, deviceName, moduleId) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/config/modules/${moduleId}`, {
    "env_vars": formInput
  })
  .then(resp => {
    dispatch(updateConfigSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(updateConfigFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}


export const updateGlobalConfigByName = (formInput, deviceName) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/config/global`, {
    "env_vars": formInput
  })
  .then(resp => {
    dispatch(updateGlobalConfigSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(updateGlobalConfigFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}
