import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_FAILURE = "CREATE_FOLDER_FAILURE";
export const GET_FOLDER_SUCCESS = "GET_FOLDER_SUCCESS";
export const GET_FOLDER_FAILURE = "GET_FOLDER_FAILURE";

const createFolderSuccess = resp => ({
  type: CREATE_FOLDER_SUCCESS,
  resp
});

const createFolderFailure = error => ({
  type: CREATE_FOLDER_FAILURE,
  error
});

const getFolderSuccess = folder => ({
  type: GET_FOLDER_SUCCESS,
  folder
});

const getFolderFailure = error => ({
  type: GET_FOLDER_FAILURE,
  error
});

export const createFolder = folder => dispatch => {
  //dispatch(request());
  return api.post(
      `/companies/${localStorage.getItem("companyId")}/folder`, {data:folder})
    .then(resp => {
      dispatch(createFolderSuccess(resp));
      //dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(createFolderFailure(error));
     // dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

export const getFolder = () =>  dispatch => {
  //dispatch(request());
  return api.get(`/companies/${localStorage.getItem("companyId")}/folder`)
        .then( resp => {
          console.log('resp-----------', resp.data.folderStructure);
          dispatch(getFolderSuccess( resp.data.folderStructure));
          //dispatch(stopSpinner());
          return Promise.resolve(resp);
        })
        .catch((error) => {
         // dispatch(getFolderFailure(error));
         // dispatch(stopSpinner());
          return Promise.reject(error);
        });
};
 