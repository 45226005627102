import { request, stopSpinner } from './helper.actions';
import { api } from './api-lib/api';
export const LIST_SCENE_SUCCESS = 'LIST_SCENE_SUCCESS';
export const LIST_SCENE_FAILURE = 'LIST_SCENE_FAILURE';
export const UPDATE_SCENE_SUCCESS = 'UPDATE_SCENE_SUCCESS';
export const UPDATE_SCENE_FAILURE = 'UPDATE_SCENE_FAILURE';
export const CREATE_SCENE_SUCCESS = 'CREATE_SCENE_SUCCESS';
export const CREATE_SCENE_FAILURE = 'CREATE_SCENE_FAILURE';
export const DELETE_SCENE_SUCCESS = 'DELETE_SCENE_SUCCESS';
export const DELETE_SCENE_FAILURE = 'DELETE_SCENE_FAILURE';
export const REST_SCENE_LIST = 'REST_SCENE_LIST';

const sceneListSuccess = resp => ({
  type: LIST_SCENE_SUCCESS,
  resp,
});

const sceneListFailure = error => ({
  type: LIST_SCENE_FAILURE,
  error,
});

export const listScenes = (page) => (dispatch) => {
  dispatch(request());
  return api.get(`/companies/${localStorage.getItem('companyId')}/scenes?page=${page}`)
  .then(resp => {
    dispatch(sceneListSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(sceneListFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const sceneUpdateSuccess = resp => ({
  type: UPDATE_SCENE_SUCCESS,
  resp,
});

const sceneUpdateFailure = error => ({
  type: UPDATE_SCENE_FAILURE,
  error,
});

export const updateScene = (sceneId, payload) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/scenes/${sceneId}`, payload)
  .then(resp => {
    dispatch(sceneUpdateSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(sceneUpdateFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const sceneCreateSuccess = resp => ({
  type: CREATE_SCENE_SUCCESS,
  resp,
});

const sceneCreateFailure = error => ({
  type: CREATE_SCENE_FAILURE,
  error,
});

export const createScene = (payload) => (dispatch) => {
  dispatch(request());
  return api.post(`/companies/${localStorage.getItem('companyId')}/scenes/`, payload)
  .then(resp => {
    dispatch(sceneCreateSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(sceneCreateFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const deleteSceneSuccess = resp => ({
  type: DELETE_SCENE_SUCCESS,
  resp,
});

const deleteSceneFailure = error => ({
  type: DELETE_SCENE_FAILURE,
  error,
});

export const deleteScene = (sceneId) => (dispatch) =>{
  dispatch(request());
  return api.delete(`/companies/${localStorage.getItem('companyId')}/scenes/${sceneId}`)
  .then(resp => {
    dispatch(deleteSceneSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(deleteSceneFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
}

export const resetSceneList = () => (dispatch) => {
  dispatch({ type: REST_SCENE_LIST })
}