import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const GET_TAG_SUCCESS = 'GET_TAG_SUCCESS';
export const GET_TAG_FAILURE = 'GET_TAG_FAILURE';

  const getTagSuccess = ( tags = {}) => {
    return {
      type: GET_TAG_SUCCESS,
      tags
  };
};

  const getTagFailure = error  => {
  return {
    type: GET_TAG_FAILURE,
    error
};
};


export const getTags = () => (dispatch) => {
    dispatch(request('hidespinner'));
    return api.get(`/companies/${localStorage.getItem('companyId')}/tags`)
      .then(resp => {
        dispatch(getTagSuccess(resp && resp.data && resp.data.tags));
        dispatch(stopSpinner());
        return Promise.resolve(resp);
      })
      .catch(error => {
        dispatch(getTagFailure(error));
        dispatch(stopSpinner());
        return Promise.reject(error);
      });
  };

  export const getDeviceTags = deviceId => {
    return api.get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceId}/tags`)
    .then(resp => {
      return Promise.resolve(resp);
    })
    .catch(error => {
      return Promise.resolve(error);
    })
  }
  