import {
    NOTIFICATION_DETAILS_SUCCESS,
    NOTIFICATION_DETAILS_FAILURE,
    UPDATE_NOTIFICATION_SUCCESS,
    UPDATE_NOTIFICATION_FAILURE
} from "../actions/settings.action";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
    notification: {}

};


const settings = (state = initialState, action) => {
    switch (action.type) {
        case API_REQUEST:
            return {
                ...state,
                pending: true
            };
        case NOTIFICATION_DETAILS_SUCCESS:
          
            return {
                ...state,
                notification: action.res.data.notificationData.notification,
                pending: false
            }
        case NOTIFICATION_DETAILS_FAILURE:

            return {
                ...state,
                notification:{},
                pending: false
            }
        case UPDATE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                notification: action.res.notification,
                pending: false
            }
        case UPDATE_NOTIFICATION_FAILURE:

            return {
                ...state,
                notification:{},
                pending: false
            }
        default:
            return state;
    }
}


export default settings;