import { request, stopSpinner } from './helper.actions';
import { api } from './api-lib/api';
export const LIST_LOCATION_SUCCESS = 'LIST_LOCATION_SUCCESS';
export const UNLIST_LOCATION_SUCCESS = 'UNLIST_LOCATION_SUCCESS';
export const LIST_LOCATION_FAILURE = 'LIST_LOCATION_FAILURE';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAILURE = 'UPDATE_LOCATION_FAILURE';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAILURE = 'CREATE_LOCATION_FAILURE';
export const ASSIGN_LOCATION_SUCCESS = 'ASSIGN_LOCATION_SUCCESS';
export const ASSIGN_LOCATION_FAILURE = 'ASSIGN_LOCATION_FAILURE';
export const LIST_LOCATION_WITH_DEVICE_COUNT_SUCCESS = 'LIST_LOCATION_WITH_DEVICE_COUNT_SUCCESS';
export const LIST_LOCATION_WITH_DEVICE_COUNT_FAILURE = 'LIST_LOCATION_WITH_DEVICE_COUNT_FAILURE';
export const GET_LOCATION_DEVICES_WITH_SCREENSHOT_SUCCESS = 'GET_LOCATION_DEVICES_WITH_SCREENSHOT_SUCCESS';
export const GET_LOCATION_DEVICES_WITH_SCREENSHOT_FAILURE = 'GET_LOCATION_DEVICES_WITH_SCREENSHOT_FAILURE';
export const CLEAR_LOCATION_DEVICES_LIST_TABLE = 'CLEAR_LOCATION_DEVICES_LIST_TABLE';

const locationListSuccess = resp => ({
  type: LIST_LOCATION_SUCCESS,
  resp,
});

const locationListFailure = error => ({
  type: LIST_LOCATION_FAILURE,
  error,
});


export const getLocationsWithDevices = ( hidespinner, pageCount ) => dispatch => {
     
  dispatch(request( hidespinner ));
  
  let pageNo='';

  if (pageCount) {
    pageNo = `?page=${pageCount}`;
  }
  return api.get(`/companies/${localStorage.getItem('companyId')}/locations-with-devices${pageNo}`)
  .then(resp => {
    dispatch(locationListSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(locationListFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  });
}

export const listLocations = (search = '', pageNo = 1) => (dispatch) => {
  return api.get(`/companies/${localStorage.getItem('companyId')}/locations?page=${pageNo}&search=${search}`)
  .then(resp => {
    dispatch(locationListSuccess(resp));
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(locationListFailure(error));
    return Promise.reject(error);
  });
};


const locationListDeviceCountSuccess = resp => ({
  type: LIST_LOCATION_WITH_DEVICE_COUNT_SUCCESS,
  resp,
});

const locationListDeviceCountFailure = error => ({
  type: LIST_LOCATION_WITH_DEVICE_COUNT_FAILURE,
  error,
});

export const listLocationsDeviceCount = ( hidespinner, pageCount ) => (dispatch) => {
  
  let pageNo='';

  if (pageCount) {
    pageNo = `?page=${pageCount}&limit=20`;
  }
  return api.get(`/companies/${localStorage.getItem('companyId')}/locations/devicecount${pageNo}`)
  .then(resp => {
    dispatch(locationListDeviceCountSuccess(resp));
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(locationListDeviceCountFailure(error));
    return Promise.reject(error);
  });
};

const locationUpdateSuccess = resp => ({
  type: UPDATE_LOCATION_SUCCESS,
  resp,
});

const locationUpdateFailure = error => ({
  type: UPDATE_LOCATION_FAILURE,
  error,
});

export const updateLocation = (locationId, payload) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/locations/${locationId}`, payload)
  .then(resp => {
    dispatch(locationUpdateSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(locationUpdateFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

const getLocationDevicesWithScreenshotSuccess = resp => ({
  type: GET_LOCATION_DEVICES_WITH_SCREENSHOT_SUCCESS,
  resp,
});

const getLocationDevicesWithScreenshotFailure = error => ({
  type: GET_LOCATION_DEVICES_WITH_SCREENSHOT_FAILURE,
  error,
});

export const getLocationDevicesWithScreenshot = (locationName, page) => (dispatch) =>{
   
  return api.get(`/companies/${localStorage.getItem('companyId')}/locations/${locationName}/devices-with-screenshot?page=${page}`)
  .then(resp => {
    dispatch(getLocationDevicesWithScreenshotSuccess(resp.data));
    return Promise.resolve(resp.data);
  })
  .catch(error => {
    dispatch(getLocationDevicesWithScreenshotFailure(error));
    return Promise.reject(error);
  })
}

const createLocationSuccess = resp => (
  {
    type: CREATE_LOCATION_SUCCESS,
    resp,
  }
);

const  createLocationFailure = error => (
  {
    type: CREATE_LOCATION_FAILURE,
    error,
  }
)

export const createLocation = (locationObj) => (dispatch) => {
  dispatch(request());
  return api.post(`/companies/${localStorage.getItem('companyId')}/locations`, { location: locationObj })
    .then(resp => {
      dispatch(createLocationSuccess(resp))
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(createLocationFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    })
};

const assignLocationSuccess = resp => ({
  type: ASSIGN_LOCATION_SUCCESS,
  resp,
});

const assignLocationFailure = error => ({
  type: ASSIGN_LOCATION_FAILURE,
  error,
});

export const submitDevicesForLocation = (locationId, payload) => (dispatch) => {
  dispatch(request());
  return api.put(`/companies/${localStorage.getItem('companyId')}/locations/${locationId}/devices`, payload)
  .then(resp => {
    dispatch(assignLocationSuccess(resp));
    dispatch(stopSpinner());
    return Promise.resolve(resp);
  })
  .catch(error => {
    dispatch(assignLocationFailure(error));
    dispatch(stopSpinner());
    return Promise.reject(error);
  })
};

export const unListLocation = () => (dispatch) => {
  dispatch({
    type: UNLIST_LOCATION_SUCCESS
  })
};

export const clearLocationDeviceListTable = () => dispatch => {
  dispatch({
    type: CLEAR_LOCATION_DEVICES_LIST_TABLE
  })
}