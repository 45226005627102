import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const GET_DEVICE_ALERTS_SUCCESS = "GET_DEVICE_ALERTS_SUCCESS";
export const GET_DEVICE_ALERTS_FAILURE = "GET_DEVICE_ALERTS_FAILURE";

const getDeviceAlertsSuccess = resp => ({
  type: GET_DEVICE_ALERTS_SUCCESS,
  resp
});

const getDeviceAlertsFailure = error => ({
  type: GET_DEVICE_ALERTS_FAILURE,
  error
});

export const getDeviceAlerts = deviceName => dispatch => {
  dispatch(request());
  return api
    .get(`/companies/${localStorage.getItem('companyId')}/devices/${deviceName}/alerts`)
    .then(resp => {
      dispatch(getDeviceAlertsSuccess(resp.data.device.alerts));
      dispatch(stopSpinner());
    })
    .catch(error => {
      dispatch(getDeviceAlertsFailure(error));
      dispatch(stopSpinner());
    });
};
