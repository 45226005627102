const {
  REACT_APP_ROOT_SSH_URL,
  REACT_APP_ROOT_PATH,
  REACT_APP_INSTALLER_ENV,
  REACT_APP_PUSHER_KEY,
} = process.env;

const ROOT_SSH_URL = REACT_APP_ROOT_SSH_URL;
const ROOT_API_URL = REACT_APP_ROOT_PATH;
const INSTALLER_ENV = REACT_APP_INSTALLER_ENV;
const PUSHER_KEY = REACT_APP_PUSHER_KEY;

export {
  ROOT_SSH_URL,
  ROOT_API_URL,
  INSTALLER_ENV,
  PUSHER_KEY
}