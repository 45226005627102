import {
  LIST_SCENE_GROUP_SUCCESS,
  LIST_SCENE_GROUP_FAILURE,
  LIST_SCENES_SUCCESS,
  LIST_SCENES_FAILURE,
  LIST_SCENE_TYPE_SUCCESS,
  LIST_SCENE_TYPE_FAILURE,
  UPDATE_SCENE_TYPE_SUCCESS,
  UPDATE_SCENE_TYPE_FAILURE,
  UPDATE_SCENE_GROUP_SUCCESS,
  UPDATE_SCENE_GROUP_FAILURE,
  CREATE_SCENE_GROUP_SUCCESS,
  CREATE_SCENE_GROUP_FAILURE,
  DELETE_SCENE_SUCCESS,
  DELETE_SCENE_FAILURE,
  GET_SCENES_BY_TYPE_SUCCESS,
  DELETE_SCENE_GROUP_SUCCESS,
  DELETE_SCENE_GROUP_FAILURE,
} from "../actions/sceneGroups.actions";
import { API_REQUEST } from "../actions/helper.actions";

const initialState = {
  error: "",
  items: [],
  sceneitems: [],
  sceneGroups: {},
  sceneTypes: [],
  scenesByType: [],
};

const sceneGroups = (state = initialState, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        pending: true
      };
    case LIST_SCENE_GROUP_SUCCESS:
      return {
        ...state,
        items: action.resp.data.sceneGroups
      };
    case LIST_SCENE_GROUP_FAILURE:
      return {
        ...state,
        items: [],
        error: action.error
      };
    case LIST_SCENES_SUCCESS:
      return {
        ...state,
        sceneitems: action.resp.data.sceneGroup.scenes
      };
    case LIST_SCENES_FAILURE:
      return {
        ...state,
        sceneitems: [],
        error: action.error
      }
    case DELETE_SCENE_SUCCESS:
      return {
        ...state,
      };
    case DELETE_SCENE_FAILURE:
      return {
        ...state,
        sceneitems: [],
        error: action.error
      }
    case LIST_SCENE_TYPE_SUCCESS:
      return {
        ...state,
        sceneTypes: action.resp.data.sceneTypes
      };
    case LIST_SCENE_TYPE_FAILURE:
      return {
        ...state,
        sceneTypes: [],
        error: action.error
      };
    case GET_SCENES_BY_TYPE_SUCCESS:
      return {
        ...state,
        scenesByType: action.resp.data.sceneType.scenes
      }
    case DELETE_SCENE_GROUP_SUCCESS:
      return {
        ...state,
      };
    case DELETE_SCENE_GROUP_FAILURE:
      return {
        ...state,
      }
    default:
      return state;
  }
};

export default sceneGroups;
