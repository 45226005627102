import { api } from "./api-lib/api";
import { request, stopSpinner } from "./helper.actions";

export const CREATE_IOT_HUB_SUCCESS = "CREATE_IOT_HUB_SUCCESS";
export const CREATE_IOT_HUB_FAILURE = "CREATE_IOT_HUB_FAILURE";
export const LIST_IOT_HUBS_SUCCESS = "LIST_IOT_HUBS_SUCCESS"
export const LIST_IOT_HUBS_FAILURE = "LIST_IOT_HUBS_FAILURE"
export const DELETE_IOT_HUB_SUCCESS = "DELETE_IOT_HUB_SUCCESS"
export const DELETE_IOT_HUB_FAILURE = "DELETE_IOT_HUB_FAILURE";
export const UPDATE_IOT_HUBS_SUCCESS = "GET_IOT_SUCCESS"

const getIotHubSuccess = (iot) => ({
  type: UPDATE_IOT_HUBS_SUCCESS,
  iot
});

export const updateIoTHub = (iot) => dispatch => {
  const {
    name,
    connection_string
  } = iot
  dispatch(request());
  return api
    .put(
      `/companies/${localStorage.getItem(
        "companyId"
      )}/iotHubs/${name}`, 
      {
        iotHubs : {connection_string}
      }
    )
    .then(resp => {
      dispatch(getIotHubSuccess(resp.data.iotEdgesObj));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const createIotHubSuccess = resp => ({
  type: CREATE_IOT_HUB_SUCCESS,
  resp
});

const createIotHubFailure = error => ({
  type: CREATE_IOT_HUB_FAILURE,
  error
});

export const createIoTHub = iotHub => dispatch => {
  dispatch(request());
  return api
    .post(
      `/companies/${localStorage.getItem(
        "companyId"
      )}/iotHubs`, iotHub
    )
    .then(resp => {
      dispatch(createIotHubSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(createIotHubFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const listIotHubsSuccess = resp => ({
  type: LIST_IOT_HUBS_SUCCESS,
  resp
});

const listIotHubsFailure = error => ({
  type: LIST_IOT_HUBS_FAILURE,
  error
});

export const listIotHubs = iotHub => dispatch => {
  dispatch(request());
  return api
    .get(
      `/companies/${localStorage.getItem(
        "companyId"
      )}/iotHubs`
    )
    .then(resp => {
      dispatch(listIotHubsSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(listIotHubsFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};

const deleteIotHubSuccess = resp => ({
  type: DELETE_IOT_HUB_SUCCESS,
  resp
});

const deleteIotHubFailure = error => ({
  type: DELETE_IOT_HUB_FAILURE,
  error
});

export const deleteIotHub = iotHub => dispatch => {
  dispatch(request());
  return api
    .delete(
      `/companies/${localStorage.getItem(
        "companyId"
      )}/iotHubs/${iotHub.id}`
    )
    .then(resp => {
      dispatch(deleteIotHubSuccess(resp));
      dispatch(stopSpinner());
      return Promise.resolve(resp);
    })
    .catch(error => {
      dispatch(deleteIotHubFailure(error));
      dispatch(stopSpinner());
      return Promise.reject(error);
    });
};
