export const SUCCESS_ALERT='SUCCESS_ALERT';
export const FAILURE_ALERT='FAILURE_ALERT';
export const CLEAR_BOTH_ALERT='CLEAR_BOTH_ALERT';

const validateData = (data) => {
  if(typeof data === 'string'){
    return data;
  }else{
    return 'Invalid error!!';
  }
}

const successAlert = (message) => {
  return {
    type: SUCCESS_ALERT,
    message: validateData(message),
  }
}

const failureAlert = (error) => {
  return {
    type: FAILURE_ALERT,
    error: validateData(error),
  }
}

export const successAlertHandler = message => (dispatch) => {
  setTimeout(() => dispatch(clearBothAlertHandler()),3000);
   return dispatch(successAlert(message));
};

export const failureAlertHandler = error => (dispatch) => {
  setTimeout(() => dispatch(clearBothAlertHandler()),5000);
  return dispatch(failureAlert(error));
}

export const clearBothAlertHandler = () => {
  return {
    type: CLEAR_BOTH_ALERT
  }
}
