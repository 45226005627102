import fetchIntercept from 'fetch-intercept';
let interceptorObj = '';
class Interceptor {
  constructor() {
    this.interceptHandler = this.interceptHandler.bind(this);
    this.handleRejectedResponses = this.handleRejectedResponses.bind(this);
  }

  handleRejectedResponses(response, callback) {
    switch (response.status) {
      case 401:
        callback(response);
        return response.json()
          .then(data => Promise.reject(data.extra.error));
      case 422:
      case 400:
        return response.json()
          .then(data => Promise.reject(data.extra.error));
      default:
        return response;
    }
  };

  interceptHandler(self, callback) {
    fetchIntercept.register({
      request: function (url, config) {
        return [url, config];
      },

      requestError: function (error) {
        return Promise.reject(error);
      },

      response: function (response) {
        return self.handleRejectedResponses(response, callback);
      },

      responseError: function (error) {
        return Promise.reject(error);
      }
    });
  };
}

if(!interceptorObj){
  interceptorObj = new Interceptor();
}

export default interceptorObj;
