import {
    GET_CONFIG_SUCCESS,
    GET_CONFIG_FAILURE,
    UPDATE_GLOBAL_CONFIG_SUCCESS,
    UPDATE_GLOBAL_CONFIG_FAILURE,
    UPDATE_CONFIG_SUCCESS,
    UPDATE_CONFIG_FAILURE
  } from '../actions/config.actions';
  
  
  const initialState = {
    config: [],
  }
  
  const config = (state = initialState, action) => {
    switch(action.type){
      case GET_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.resp.data.device.config,
        pending: false,
      }

      case GET_CONFIG_FAILURE:
      return{
        ...state,
        pending: false,
      }

      case UPDATE_GLOBAL_CONFIG_SUCCESS:
      return{
        ...state,
        state: {
          config: {
            global: action.resp.data.updatedDevice.config.global
          }
        },
        pending: false,
      }

      case UPDATE_GLOBAL_CONFIG_FAILURE:
      return{
        ...state,
        pending: false,
      }

      case UPDATE_CONFIG_SUCCESS:
      const newConfig = JSON.parse(JSON.stringify(state.config));
      newConfig.modules = action.resp.data.device.config.modules;
      return{
        ...state,
        config: newConfig,
        successMsg: action.resp.data.message,
        pending: false,
      }
      

      case UPDATE_CONFIG_FAILURE:
      return{
        ...state,
        pending: false,
      }

      default:
      return state
    }
  }
  
  export default config;